export const orderEn = {
 order_payment_method: 'Payment Method',
 order_promptpay: 'Promptpay',
 order_max_money_tip: 'The amount of an order placed at the same store exceeds 50,000 Baht Does not support COD payment method',
}

export const orderTh = {
  order_payment_method: 'วิธีการชำระเงิน',
  order_promptpay: 'Promptpay',
  order_max_money_tip: 'หากจำนวนคำสั่งซื้อจากร้านค้าเดียวกันเกิน 50,000 บาท ไม่สามารถใช้การจ่ายปลายทางได้่',
}