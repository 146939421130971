export const selectionEn = {
  All: `All`,
  Sales: `Sales`,
  'Total Sales': `Total Sales`,
  'Last 30 Days': `Last 30 Days`,
  '30-Day Sales': `30-Day Sales`,
  Price: `Price`,
  Commission: `Comm`,
  'Commission%': `Comm%`,
  Creators: `Creators`,
  '#Subsidy': `Subsidy`,
  '#Free Samples': `Free Samples`,
  '#Promotion Reward': `Promotion Reward`,
  Comm: `Comm`,
  Earn: `Earn`,
  'Sales Growth %': `Sales Growth%`,
  Cancel: `Cancel`,
  'View Details': 'Get Free Sample',
  'No More': 'No More Products~',
  全部商品: `All`,
  xxx起: `Min. ฿{price}`,
  批量添加橱窗: `Add all to showcase`
}

export const selectionTh = {
  All: `ทั้งหมด`,
  Sales: `ยอดขาย`,
  'Total Sales': `ยอดขายรวม`,
  'Last 30 Days': `30 วันที่ผ่านมา`,
  '30-Day Sales': `ยอดขาย 30 วัน`,
  Price: `ราคา`,
  Commission: `ค่าคอม`,
  'Commission%': `ค่าคอม%`,
  Creators: `ครีเอเตอร์`,
  '#Subsidy': `เงินอุดหนุน`,
  '#Free Samples': `สินค้าตัวอย่าง`,
  '#Promotion Reward': `รางวัลโปรโมท`,
  Comm: `ค่าคอม`,
  Earn: `รายได้`,
  'Sales Growth %': `ยอดขายเติบโต %`,
  Cancel: `ยกเลิก`,
  'View Details': 'ขอสินค้าตัวอย่าง',
  'No More': 'สินค้าทั้งหมดได้แสดงดังกล่าว',
  全部商品: `สินค้าทั้งหมด`,
  xxx起: `ราคาเริ่มต้น ฿{price}`,
  批量添加橱窗: `เพิ่มทั้งหมดลงในโชว์เคส`
}
