/* eslint-disable import/prefer-default-export */
import http from './http'
import route from '../utils/route'
/**
 * http://api.catike.bagli.me/apitest
 */
const api = {
  getShare450Count() {
    return http.get(`api-member/member/invitedCount`)
  },
  getShare450Gmv() {
    return http.get(`api-mall/lazada/order/invitedGmv`)
  },
  getShare450IsFinished() {
    return http.get(`api-base/pop/isFinished`)
  },
  /**
   * 更新用户信息(设置)
   */
  updateUserDetail(data) {
    return http.put('api/user', data)
  },

  /**
   * 绑定小程序手机号，这个手机号不需要验证码
   */
  bindMPMobile(data) {
    return http.put('api/user/wechatMobile', data)
  },

  /**
   * 保存微信form id
   ```javascript
   const data = {
     wechatFormId, // required
     userId, // required
     source
   }
   ```
   */
  saveFormId(data) {
    return http.post('/wechat/formId', data)
  },

  /**
   * 微信登录
   * 参数：
   *  - code
   */
  wechatLogin: (data) => {
    return http.post('api/wechat/mpLogin', data)
  },
  /**
   * 更新用户信息(微信)
   */
  updateUserInfo(data) {
    return http.put('api/user', data)
  },

  /**
   * 获取用户信息
   */
  getUserInfo() {
    return http.get('api-member/member/account/current')
  },
  getUserMobile() {
    return http.post('api-member/member/account/current/moblie')
  },

  passwordLogin(data) {
    return http.post('api-uaa/oauth/token', data)
  },
  getItemList(data, headers) {
    return http.get('api-mall/waterfall/itemWaterFallList', data, headers)
  },
  getJustForYouGoods(data) {
    return http.get('api-mall/waterfall/getJustForYouGoods', data)
  },
  getCategoryGoods(data) {
    return http.post('api-mall/waterfall/getCategoryGoods', data)
  },

  getItemDetail(data) {
    return http.get('api-mall/item/info', data)
  },

  checkOrder(data) {
    return http.post('api-mall/order/member/checkOrder', data)
  },

  payOrder(data) {
    return http.post('api-mall/order/member/placeOrder', data)
  },

  handleSignUp(data) {
    return http.post('api-member/member/account/registerMember', data)
  },

  getOrderList(data) {
    return http.get('api-mall/order/member/pageByQo', data)
  },

  getOrderDetail(data) {
    return http.get('api-mall/order/member/getOrder', data)
  },

  getAddressList() {
    return http.get('api-member/member/address/list')
  },

  getRegion(data) {
    return http.get('api-base/region/getByCode', data)
  },

  updateAddress(data) {
    return http.post('api-member/member/address/saveOrUpdate', data)
  },

  selectAddress(data) {
    return http.post('api-mall/order/member/updateAddress', data)
  },

  getCommission(data) {
    return http.get('api-mall/item/calculation', data)
  },

  payCode(data) {
    return http.post('api-mall/order/member/pay/cod', data)
  },

  getOrderCount() {
    return http.get('api-mall/order/member/listStatus')
  },

  cancelOrder(data) {
    return http.post('api-mall/order/member/cancel', data)
  },

  cancelOrderOne(data) {
    return http.post('api-mall/order/member/cancel/item', data)
  },

  getReasonList() {
    return http.get('api-mall/order/member/cancel/getType')
  },

  getReturnReason() {
    return http.get('api-mall/order/reverse/getType')
  },

  sendCode(data) {
    return http.post('api-base/sms/sendCode', data)
  },

  checkCode(data) {
    return http.post('api-base/sms/validCode', data)
  },

  checkPickItem(data) {
    return http.get('api-member/member/shop/checkPickItem', data)
  },
  changePickStatus(data) {
    const parse = route.getRouteParams()
    const { isVisitorLogin } = parse
    if (isVisitorLogin == 'true') {
      let param = JSON.stringify({
        payFlag: 'goLogin'
      })
      window.ReactNativeWebView.postMessage(param)
      return Promise.reject()
    }
    if (window.ReactNativeWebView) {
      const param = JSON.stringify({ payFlag: 'cacheToggleCollectItemId', itemId: data.itemId })
      window.ReactNativeWebView.postMessage(param)
    }
    return http.post('api-member/member/shop/changePickStatus', data)
  },

  getSearchList(data) {
    return http.post('api-mall/item/shop/homeSearch', data)
  },

  getShopDetail(data) {
    return http.get('api-user/seller/getSellerInfoById', data)
  },

  payOnLine(data) {
    return http.post('api-mall/order/member/pay/online', data)
  },

  updatePassword(data) {
    return http.post('api-member/member/account/updatePassword', data)
  },

  getViewHistory(data) {
    return http.get('api-mall/item/shop/getViewedItem', data)
  },

  getHomeCarousel() {
    return http.get('api-base/banner/getBanner')
  },
  getHomeActivity() {
    return http.get('api-base/home/getHomeNew')
  },
  getHomeCategoryList() {
    return http.get('api-base/home/getHomeCategoryList')
  },
  getHomeCategoryGoodsList(data) {
    return http.get('api-mall/activity/app/getHomeCategoryGoodsList/v1', data)
  },
  getAppHomeConfigList(data) {
    return http.get('api-base/home/getAppHomeConfigList', data)
  },
  toConfirmPay(data) {
    return http.post('api-mall/order/member/toConfirmPay', data)
  },

  getStoreItemList(data) {
    return http.get('api-mall/item/shop/getItemListBySellerId', data)
  },

  getByInviteCode(data) {
    return http.get('api-member/member/getByInviteCode', data)
  },

  bindMember(data) {
    return http.post('api-member/member/account/bindMember', data)
  },

  listPickItem(data) {
    return http.get('api-mall/item/shop/listPickItem', data, { noToken: true })
  },

  getShippingFee(data) {
    return http.get('api-mall/item/calculateShippingFee', data)
  },

  getExpressDetail(data) {
    return http.post('api-base/logistic/queryLogisticRoutes', data)
  },

  getBankAccount() {
    return http.post('api-mall/order/reverse/getBankInfo')
  },

  checkBindBankCard(data) {
    return http.get('api-mall/order/reverse/checkBindBankCard', data)
  },

  creatBank(data) {
    return http.post('api-mall/order/reverse/saveBankInfo', data)
  },
  reverseOrderBindBankCard(data) {
    return http.post('api-mall/order/reverse/reverseOrderBindBankCard', data)
  },

  returnSubmit(data) {
    return http.post('api-mall/order/reverse/commit', data)
  },

  getBankList() {
    return http.get('api-base/bank/list')
  },

  getReturnList(data) {
    return http.get('api-mall/order/reverse/list', data)
  },

  getDailyList() {
    return http.get('api-mall/spike/item/getHomeSpike')
  },

  getDailyTabs() {
    return http.get('api-mall/spike/item/getSpike')
  },

  getDailyDetail(data) {
    return http.get('api-mall/spike/item/getSpikeInfo', data)
  },

  getSpikeList(data) {
    return http.get('api-mall/spike/item/getSpikeItemList', data)
  },

  getCouponList() {
    return http.get('api-mall/coupon/member/inviteCouponList')
  },

  otherRegister(data) {
    return http.post('api-member/member/account/registerOpenId', data)
  },

  getReceiveList(data) {
    return http.get('api-mall/order/member/toReceiveMsg', data)
  },

  lineLogin(data) {
    return http.post('api-member/member/account/authByLine', data)
  },

  uploadImg(data) {
    return http.post('api-base/upload/uploadFile', data)
  },

  getActivityList(data) {
    return http.get('api-mall/activity/app/getActivityItemPage', data)
  },

  getActivityDetail(data) {
    return http.get('api-mall/activity/app/getActivityItemInfo', data)
  },

  getHomeActivityList(data) {
    return http.get('api-base/home/getHomeList', data)
  },
  // 查询活动时间
  getActivityTime() {
    return http.get('api-mall/activity/getTime')
  },
  // 查询活动榜单列表
  getActivityRank(data) {
    return http.get('api-mall/activity/getGVM', data)
  },
  getMainActivityDetail() {
    return http.get('api-mall/activity/app/getBirthdayActivityPageVo')
  },
  cancelList(data) {
    return http.post('api-mall/order/member/cancel/list', data)
  },
  cancelDetail(data) {
    return http.post('api-mall/order/member/cancelDetail', data)
  },
  returnDetail(data) {
    return http.get('api-mall/order/reverse/detailByReverseId', data)
  },
  returnDetail2(data) {
    return http.get('api-mall/order/reverse/detail', data)
  },
  cancelRefund(data) {
    return http.get('api-mall/order/reverse/cancelApply', data)
  },
  reverseApplyReturn(data) {
    return http.post('api-mall/order/reverse/applyReturn', data)
  },
  getActiveTime: (params, headers) => http.get('api-mall/activity/getActiveTime', params, headers),
  statisticsClick(params) {
    if (params.source) {
      return http.post('api-base/statistics/click', params)
    }
  },
  getGiftItemList: (params, headers) =>
    http.get('api-mall/activity/getGiftItemList', params, headers),
  getGeneralItemList: (params, headers) =>
    http.get('api-mall/activity/getGeneralItemList', params, headers),
  getMemberRank: (headers) => http.get('api-mall/activity/memberRank', undefined, headers),
  getBirthdayActivityPageVo: (headers) =>
    http.get('api-mall/activity/app/getBirthdayActivityPageVo', undefined, headers),
  getCoupon: (data) => http.post('api-mall/coupon/member/getCoupon', data),
  createProptPayOrder: (data) => http.post('api-mall/order/pay/promptPay', data),
  getCodeUrl: (orderNo) => http.get('api-base/KsherPay/getCodeUrl', { orderNo }),
  removeAddressById: (id) => http.post('api-member/member/address/removeById', { id }),
  getActivityYpc: (params, headers) =>
    http.get('api-mall/activity/getActivityYpc', params, headers), //自己销售lo礼包的展示
  getActivityYpcRank: (params, headers) =>
    http.get('api-mall/activity/getActivityYpcRank', params, headers), //所有人销售lo礼包的展示
  getTimeRank: (headers) => http.post('api-mall/activity/getTimeRank/pro', {}, undefined, headers), //倒计时时间控制
  getFlashTime: (headers) => http.post('api-mall/activity/getFlashTime', {}, undefined, headers), //数据更新时间
  myRank: (params, headers) => http.get('api-mall/activity/myRank/pro', params, headers), //获取本人当前排名
  listForRank: (params, headers) => http.get('api-mall/activity/listForRank/pro', params, headers), //获取排行列表
  getOrderSupportCod: (orderNo) => http.get('api-mall/order/member/getOrderCodStatus', { orderNo }),
  // lazada商品详情
  lazadaItemDetail(data, headers) {
    return http.get('api-mall/lazada/affiliate/item/appDetail', data, headers)
  },
  // lazada列表
  lazadaList(data) {
    return http.get('api-mall/lazada/affiliate/item/appPageByQo', data)
  },
  // ypc plus 列表
  ypcplusList() {
    return http.get('api-mall/activity/app/getBirthdayActivityPageVo?activityType=7')
  },
  // 设置用户协议是否同意
  setAuthPrivacyStatus(params, headers) {
    return http.post('api-member/member/setAuthPrivacyStatus', params, undefined, headers)
  },
  getCouponInfoListByIds: (data) =>
    http.post('api-mall/customize/activity/getCouponInfoListByIds', data),
  // 更新最后一次在线时间
  updateOnlineTime: (data) =>
    http.post('api-uchoice/uChoice/member/updateLastOnlineTime', data, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }),
  itemReviews(data) {
    return http.get('api-mall/reviews/item/itemReviews', data)
  },
  itemReviewsPage(data) {
    return http.get('api-mall/reviews/item/itemReviewsPage', data)
  },
  // 钉钉监控服务端异常机器人
  dingtalkHttpMonitorRobotSend(param) {
    return http.post(
      'https://oapi.dingtalk.com/robot/send?access_token=8bfaf937d0ce1b9fb60c745f5b061d3d15fb8326f673a7826bf90233b9be54ba',
      param
    )
  },
  // 查询商品信息（券类商品）
  couponItemInfo(data, headers) {
    return http.get('api-mall/item/couponItemInfo', data, undefined, headers)
  },
  // 下单校验(虚拟商品)
  virtualCheckOrder(data, headers) {
    return http.post('api-mall/order/member/virtualCheckOrder', data, undefined, headers)
  },
  // 下单(虚拟商品)
  placeVirtualOrder(data, headers) {
    return http.post('api-mall/order/member/placeVirtualOrder', data, undefined, headers)
  },
  // lazadaCategory 分类
  getCategoryList(data) {
    return http.get('api-mall/lazada/affiliate/item/categoryYoupik', data)
  },
  // lazada获取商品链接
  lazadaGetProductUrl(data) {
    return http.get('api-mall/lazada/affiliate/item/productUrl', data)
  },
  getAnniversaryOrderNum: (params, headers) =>
    http.get('api-mall/activity/app/getAnniversaryOrderNum', params, headers),
  getAnniversaryRankings: (params, headers) =>
    http.get('api-mall/activity/app/getAnniversaryRankings', params, headers), //所有人销售lo礼包的展示
  // lazada获取链接
  lazadaGetUrl(data) {
    return http.get('api-mall/lazada/affiliate/item/lazadaUrl', data)
  },
  // 高佣精选
  highCommissionItem(data) {
    return http.get('api-mall/lazada/affiliate/item/highCommissionItem', data)
  },
  // 高佣首页配置
  getAppHomeConfigListByType(data) {
    return http.get('api-base/home/getAppHomeConfigListByType', data)
  },
  youpikPickGoods(param) {
    return http.get(`api-mall/item/shop/listPickItemByPage`, param)
  },
  // 自定义活动列表
  activityAppGetCustomEventInfo(param) {
    return http.get('api-mall/customize/activity/app/getCustomEventInfo', param)
  },
  // APP-转换lazada链接
  lazadaUrlConvert(param) {
    return http.get('api-mall/lazada/affiliate/item/lazadaUrlConvert', param)
  },
  // 获取FAQ详情
  getQuestionDetail(params) {
    return http.get('api-user/faq/getQuestionDetail', params)
  },
  // 瀑布流商品
  appWaterfallItem(params) {
    return http.get('api-mall/lazada/affiliate/item/appWaterfallItem', params)
  },
  getLeaderboardCategory(param) {
    return http.get(`api-mall/lazada/leaderboard/getLeaderboardCategory`, param)
  },
  getItemShopByLeaderboardId(leaderboardId) {
    return http.get(`api-mall/lazada/leaderboard/getItemShopByLeaderboardId`, { leaderboardId })
  },
  getItemShopById(leaderboardId) {
    return http.get(`api-mall/lazada/leaderboard/getItemShopById`, { leaderboardId })
  },
  //tiktok首页类目
  getTiktokHomeCategories(param) {
    return http.get(`api-uchoice/tt/item/getTtaCategoryInfo`, param)
  },
  //tiktok商品搜索
  searchTiktokItem(param) {
    return http.get(`api-uchoice/tt/item/searchItem`, param)
  },
  //获取tiktok首页商品
  getTiktokHomeItem(param) {
    return http.get(`api-uchoice/tt/item/getTtaItemList`, param)
  },
  //收藏tiktok商品
  collectTiktokItem(param) {
    return new Promise((resolve, reject) => {
      http
        .get(`api-uchoice/tt/h5/getMyCollect`, param, {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          silent: 1
        })
        .then((res) => {
          if (res.code === 200) {
            http
              .post(`api-uchoice/tt/h5/updateMemberCollect`, param, undefined, {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              })
              .then((a) => {
                resolve(a)
              })
              .catch((e) => {
                reject(e)
              })
          }
        })
        .catch((e) => {
          if (e.code === 33034) {
            window.bindMobile()
            reject()
          }
        })
    })
  },
  //tiktok我的收藏列表
  getTiktokMyCollection(param) {
    return http.get(`api-uchoice/tt/h5/getMyCollect`, param, { silent: 1 })
  },
  // tt查询商品详情
  getiktokInfo(param) {
    return http.get(`api-uchoice/tt/item/info`, param)
  },
  //收藏操作
  updateMemberCollect: (params) =>
    http.post(`api-uchoice/tt/h5/updateMemberCollect`, params, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }),
  //一键清空收藏
  clearMyExpiredCollection(param) {
    return http.post(`api-uchoice/tt/h5/clearH5MyCollectionProduct`, param)
  },
  //下单奖励活动信息查询
  queryH5ShowInfo: (params, headers) =>
    http.get('api-member/noOrderActivity/queryH5ShowInfo', params, headers),
  //tt判断能否复制链接
  isCanCopyLink(param) {
    return http.get(`api-uchoice/tiktok/isCanCopyLink`, param)
  },
  //新增tt账号前置判断
  beforeAddTikTokAccount(param) {
    return http.post(`api-uchoice/tiktok/beforeAddTikTokAccount`, param)
  },
  //tt绑定手机号
  bindMobile(param) {
    return http.post(`api-uchoice/tiktok/bindMobile`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  //获取tiktok授权列表
  getTtAccountList(param) {
    return http.get(`api-uchoice/tiktok/getTikTokAccountList`, param)
  },
  //获取失效tiktok列表
  getInvalidAccount(param) {
    return http.get(`api-uchoice/tiktok/getInvalidAccount`, param)
  },
  //合并到当前账号
  mergeToCurrentAccount(param) {
    return http.post(`api-uchoice/tiktok/mergeToCurrentAccount`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  //合并到其他账号
  mergeToOtherAccount(param) {
    return http.post(`api-uchoice/tiktok/mergeToOtherAccount`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  // tiktok授权接口
  tiktokAuth(param) {
    return http.post(`api-uchoice/tiktok/auth`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  // tiktok授权登录-注册,获取openId
  tiktokGetTikTokOpenId(param) {
    return http.post(`api-uchoice/tiktok/getTikTokOpenId`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  //失效tt账号不再提醒
  neverRemind(param) {
    return http.post(`api-uchoice/tiktok/noRemindMe`, param, undefined, {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    })
  },
  transferYoupik2uChoice(param) {
    return http.get(`api-uchoice/uChoice/member/migrateUsers`, param, {
      silent: true
    })
  },
  getTtaItemListByProductIdsStr(param) {
    return http.get(`api-uchoice/tt/item/getTtaItemListByProductIdsStr`, param, {
      silent: true
    })
  }
}
export default api
