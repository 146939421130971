export const itemEn = {
  max_weight_tip: 'The maximum weight of a single purchase has been reached',
  bestseller_recommended_title1: `Top Choice For you`,
  bestseller_recommended_title2: '',
  bought_num_in_past_30_days: (numStr) => `${numStr} users bought it in the past 30 days`,
  bestseller_title: 'Best Sellers',
  Wishlist: 'Wishlist'
}

export const itemTh = {
  max_weight_tip: 'น้ำหนักถึงขีดจำกัดมากสุดของการสั่งซื้อ',
  bestseller_recommended_title1: 'สินค้าขายดีประจำสัปดาห์นี้',
  bestseller_recommended_title2: 'ช้อปเลย!',
  bought_num_in_past_30_days: (numStr) => `มี ${numStr} คนซื้อสินค้าชินนี้ภายใน 30 วันที่ผ่านมา`,
  bestseller_title: 'สินค้าขายดี',
  Wishlist: 'รายการโปรด'
}
