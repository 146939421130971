export const resultEn = {
  result_tip_1: "Please complete your payment within ",
  result_tip_2: " If not completed in time, order will be automatically cancelled. If already completed payment but not showing real time in order status. Please don't do the transaction again.",
  wait_tip_1: 'For more detail，track your delivery status under',
  wait_tip_2: 'My Account > Purchase History',
  btn_home: 'Home',
  code_tip: 'Please have this amount ready on delivery day',
  order_received: 'Order received',
  waiting_for_payment: 'waiting for payment',
  pay_it_again: 'Pay it again',
}

export const resultTh = {
  result_tip_1: "กรุณาชำระเงินให้แล้วเสร็จภายใน ",
  result_tip_2: " หากไม่ชำระเงินในเวลาที่กำหนด คำสั่งซื้อจะถูกยกเลิกโดยอัติโนมัติ หากชำระเงินเสร็จสิ้นแล้วแต่สถานะสินค้ายังไม่เปลี่ยนแปลง กรุณาอย่าชำระเงินอีกครั้ง",
  wait_tip_1: 'ติดตามพัสดุของคุณที่นี',
  wait_tip_2: 'บัญชีของฉัน> คำสั่งซื้อของฉัน',
  btn_home: 'หน้าหลัก',
  code_tip: 'กรุณาเตรียมเงินตามจำนวนที่ระบุให้พร้อมในวันจัดส่ง',
  order_received: 'สั่งซื้อสำเร็จ',
  waiting_for_payment: 'รอการชำระเงิน',
  pay_it_again: 'ลองใหม่อีกครั้ง',
}