import React, { useEffect, useState } from 'react'
import i18n from 'taro-i18n'
import { init } from '@umengfe/apm'
import vhCheck from 'vh-check' //移动端浏览器100vh高度不一致 vhCheck();
import TtAuth from '@/pages/tiktok/components/ttAuth'
import PhoneBinding from '@/pages/tiktok/components/phoneBinding'
import Combine from '@/pages/tiktok/components/combine'
import 'animate.css'
import api from '@/api/api'
import user from '@/api/user'
import Taro from '@tarojs/taro'
import platform from '@/utils/platform'
import { checkIsCanCopyLink } from '@/utils/tiktok'
import route from '@/utils/route'
import locales from './utils/locales'
import './app.scss'
import { I18n } from '@youpik/mobile_components'
import { StatisticWatcher } from './utils/statistic/Watcher'

init({
  pageFilter: { mode: 'ignore', rules: [] },
  pid: '629f270705844627b5a4a516'
})

window.platform = platform()

const App = (props) => {
  const [showTtAuth, setShowTtAuth] = useState(false) // tiktok授权弹窗
  const [showBind, setShowBind] = useState(false) // 绑定手机弹窗
  const [combineModalVisible, setCombineModalVisible] = useState(false) // 合并弹窗
  const [bindMobileCombineData, setBindMobileCombineData] = useState() // 绑定手机失败回调过来的的供合并的数据

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      vhCheck()
    }

    const { language } = route.getRouteParams()
    const lan = language || (navigator.language?.includes('th') ? 'th' : 'en')
    I18n.changeLanguage(lan)
    i18n.t = new i18n(lan, locales)
  }, [])

  useEffect(() => {
    window.tiktokAuthModalShow = () => {
      setShowTtAuth(true)
    }

    window.bindMobile = () => {
      setShowBind(true)
    }
  }, [])

  useEffect(() => {
    let dontHandle = false

    const referer = localStorage.getItem('referer') || ''
    if (
      referer.includes('pages/protocol/privacyPolicy') ||
      referer.includes('pages/protocol/termOfUse')
    ) {
      dontHandle = true
    }

    for (const div of document.getElementsByTagName('div')) {
      if (
        div.getAttribute('id') &&
        (div.getAttribute('id').includes('pages/protocol/privacyPolicy') ||
          div.getAttribute('id').includes('pages/protocol/termOfUse'))
      ) {
        div.style.zIndex = 52226
        dontHandle = true
        localStorage.setItem('referer', div.getAttribute('id'))
      }
    }

    if (!dontHandle) {
      setShowTtAuth(false)
      setShowBind(false)
      setCombineModalVisible(false)
    }

    if (platform() === 'ios' && window.location.href.includes('/pages/tiktok')) {
      checkIsCanCopyLink()
    }
  }, [props.children])

  const onClose = () => {
    setShowTtAuth(false)
    Taro.showToast({
      title: i18n.t._('Copy_failed__please_authorize_tiktok_account_and_try_again_'),
      icon: 'none'
    })
  }

  // 如果绑定了别人的手机，就需要合并到他的账号
  const onNeedCombineBackward = ({ list, memberId }) => {
    console.log('onNeedCombineBackward:', { list, memberId })
    setShowBind(false)
    setBindMobileCombineData({ list, memberId })
    setCombineModalVisible(true)
  }

  const onMergeSucceed = (openId) => {
    setCombineModalVisible(false)

    // 绑定手机后的合并
    requestToLoginWithOpenId(openId)
  }

  // 根据openId换token
  const requestToLoginWithOpenId = (openId) => {
    api
      .passwordLogin({
        openId,
        grant_type: 'tt_openId'
      })
      .then((res) => {
        console.log('passwordLogin res:', res)
        if (res.code === 200) {
          // 保存token
          user.setToken(res.result.access_token)
          requestToGetUserInfo()
        }
      })
  }

  const requestToGetUserInfo = () => {
    return api.getUserInfo().then((res) => {
      console.log('getUserInfo res:', res)
      if (res.code === 200) {
        // 保存用户信息
        Taro.setStorageSync('userInfo', JSON.stringify(res.result))
        Taro.showToast({ title: i18n.t._('Merge_successfully_'), icon: 'none' })

        window.location.reload()
      }
    })
  }
  return (
    <>
      {props.children}
      {showTtAuth && <TtAuth onClose={onClose}></TtAuth>}
      {showBind && (
        <PhoneBinding
          onClose={() => setShowBind(false)}
          mandatory={2}
          onNeedCombine={onNeedCombineBackward}
        ></PhoneBinding>
      )}
      {combineModalVisible && (
        <Combine
          users={bindMobileCombineData.list}
          memberId={bindMobileCombineData.memberId}
          combineForward={false}
          onSucceed={onMergeSucceed}
          onCancel={() => setCombineModalVisible(false)}
          trigger="fromBind"
        ></Combine>
      )}
      <StatisticWatcher />
    </>
  )
}

export default App
