import utils from '@/utils/utils'

const toolsTemplateEnObj = {
  "查看模板": 'View Template',
  "使用模板预览": 'Preview with Template',
  "应用模板设计及数据": 'Apply Template Design and Data',
  "应用模板后将用模板中的所有设计及数据将替换您编辑的所有设计和输入数据": `Applying the template will replace all your edited designs and data with the template's designs and data.`,
  "确定应用": 'Confirm',
  "应用模板设计": 'Apply Template Design',
  "应用模板后将用模板中的设计替换您编辑的所有模块设计，您输入的数据不变": `Applying the template will replace all your block designs with the template's designs, while your data remains unchanged.`,
  "Do you want to apply the template?": 'Do you want to apply the template?',
  "热销商品": 'Hot sale products',
  "热门商品": 'Popular products',
  "用户名称": 'your name',
  "用户简介": 'your bio text',
  应用: 'Apply'
}

const toolsTemplateThObj = {
  "查看模板": 'ดูเทมเพลต',
  "使用模板预览": 'ดูตัวอย่าง',
  "应用模板设计及数据": 'ใส่เทมเพลตและข้อมูลของคุณ',
  "应用模板后将用模板中的所有设计及数据将替换您编辑的所有设计和输入数据": 'หากคุณต้องการใช้เทมเพลตนี้ การออกแบบเพจก่อนหน้าจะหายไป',
  "确定应用": 'ยืนยัน',
  "应用模板设计": 'ใช้งานเทมเพลต',
  "应用模板后将用模板中的设计替换您编辑的所有模块设计，您输入的数据不变": 'หลังจากใช้งานเทมเพลตแล้ว เทมเพลตก่อนหน้าจะถูกแทนที่แต่ข้อมูลยังเหมือนเดิม',
  "Do you want to apply the template?": 'คุณต้องการที่จะใช้เทมเพลตนี้หรือไม่',
  "热销商品": 'สินค้าขายดี',
  "热门商品": 'สินค้ายอดนิยม',
  "用户名称": 'ชื่อ',
  "用户简介": 'Bio text',
  应用: 'ใช้เทมเพลตนี้'
}

export const toolsTemplateEn = utils.localeFormat(toolsTemplateEnObj, 'toolsTemplate')
export const toolsTemplateTh = utils.localeFormat(toolsTemplateThObj, 'toolsTemplate')
