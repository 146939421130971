export const venueEn = {
  cheapest: 'Cheapest',
  high_com: 'High Com',
  super_wow_deal: 'Super wow Deal',
  ypc_299: 'YPC 299',
  ypc_1299: 'YPC 1,299',
  v_earn: 'Earn',
  Youpik_Choice144:"Youpik Choice 144",
  Youpik_Choice155:"Youpik Choice 155",
  4:"4.-฿",
  44:"44.-฿",
  444: "444.-฿"
}

export const venueTh = {
  cheapest: 'การันตีถูกสุด',
  high_com: 'สินค้า คอมพุ่ง',
  super_wow_deal: 'Super wow Deal',
  ypc_299: 'YPC 299',
  ypc_1299: 'YPC 1,299',
  v_earn: 'ได้รับ',
  Youpik_Choice144:"Youpik Choice 144",
  Youpik_Choice155:"Youpik Choice 155",
  4:"4.-฿",
  44:"44.-฿",
  444: "444.-฿"
}
