export const EventAttr = 'youpik-event'

export const EventType = {
  Click: 1,
  View: 2,
  record_rank:3
}

export const EventName = {
  leave: 'leave',
  return: 'return',
  close: 'close',
  openApp: 'openApp',
  dist_download_android_click: 'dist_download_android_click',
  dist_download_oppo_click: 'dist_download_oppo_click',
  dist_download_vivo_click: 'dist_download_vivo_click',
  dist_download_mi_click: 'dist_download_mi_click',
  dist_download_huawei_click: 'dist_download_huawei_click',
  dist_download_apple_click: 'dist_download_apple_click'
}
