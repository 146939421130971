import utils from '@/utils/utils'

const couponEnObj = {
    voucher: 'A ฿xx voucher for you!',
    click: 'Click',
    couponCollected: 'You have a ฿xx coupon to be collected.',
    couponUsed: 'You have a ฿xx coupon to be used.',
    afterReceiving:
        '1. After receiving the coupon code and placing an order, you can get coupons and rebates.',
    pleaseclick:
        '2. Please click the button below to jump to lazada before using the code to place an order.',
    pleaseuse: '3. Please use it within 3 days after collecting it.',
    donotsend:
        '4. Do not send the coupon code to others, otherwise you will not get the commission.',
    getthecoupon: 'Get the coupon code and shop in lazada',
    getToLazada: 'Go to shop on lazada',
    couponTime: 'Valid till Aug 31, 23: 59: 59',
    howtouse: 'How to use lazada coupons?',
    step1: 'Step 1',
    clickthe: 'Click the bottom button',
    step2: 'Step 2',
    whenyouplace: 'When you place an order, paste the coupon code in here.',
    被领取完:
        'The coupon codes for this campaign have been all collected now. Welcome to participate in it next time.',
    shoponLazada: 'Coupon successfully claimed, please shop on Lazada.',
    Minspend: 'Min.spend',
    LazadaVouchers: 'Lazada Vouchers',
    AllCategories: 'All Categories'
}

const couponThObj = {
    voucher: 'A voucher ฿xx สำหรับคุณ',
    click: 'คลิก',
    couponCollected: 'คุณมีคูปอง ฿xx ที่เก็บได้',
    couponUsed: 'คุณได้ใช้คูปอง ฿xx บาทไปแล้ว',
    afterReceiving: '1. หลังจากได้รับรหัสโค้ดและทำการสั่งซื้อแล้ว คุณจะได้รับคูปองและส่วนลด',
    pleaseclick: '2. กรุณาคลิกปุ่มด้านล่างเพื่อไปที่ lazada ก่อนที่จะใช้โค้ดในการสั่งซื้อ',
    pleaseuse: '3.เมื่อได้รับโค้ดแล้ว โปรดใช้โค้ดภายใน 3 วัน',
    donotsend: '4. อย่าส่งรหัสโค้ดให้ผู้อื่น ไม่เช่นนั้น คุณจะไม่ได้รับค่าคอมมิชชั่น',
    getthecoupon: 'รับโค้ดแล้วช้อปใน lazada เลย',
    getToLazada: 'ไปที่ลาซาด้า',
    couponTime: 'ใช้ได้กับ ส.ค. 31 23: 59: 59',
    howtouse: 'วิธีใช้โค้ด',
    step1: 'ขั้นที่ 1 ',
    clickthe: 'คลิกที่ปุ่มด้านล่าง',
    step2: 'ขั้นตอนที่ 2',
    whenyouplace: ' เมื่อคุณทำการสั่งซื้อ ให้ใส่รหัสโค้ดที่นี่',
    被领取完: 'รหัสโค้ดในแคมเปญนี้ถูกเก็บหมดแล้ว มาเก็บโค้ดในครั้งต่อไปนะ',
    shoponLazada: 'การเคลมคูปองสำเร็จ กรุณาช้อปที่ Lazada',
    Minspend: 'ช้อปขั้นต่ำ',
    LazadaVouchers: 'คูปอง Lazada',
    AllCategories: 'หมวดหมู่ทั้งหมด'
}



export const couponEn = utils.localeFormat(couponEnObj, 'Coupon')
export const couponTh = utils.localeFormat(couponThObj, 'Coupon')