export const bypEn = {
  No_tip_invoices_generated_at_the_moment_: `No tip invoices generated at the moment.`,
  Use_Template_: `Use Template `,
  Guide: `Guide`,
  Preview: `Preview`,
  Publish: `Publish`,
  No_block: `No block`,
  No_block_has_been_added_yet: `No block has been added yet`,
  Add_block: `Add block`,
  Custom_domain: `Custom domain`,
  Block_theme_Settings: `Block theme Settings`,
  Re_edit: `Re-edit`,
  Please_add_blocks_before_publishing_: `Please add blocks before publishing!`,
  You_currently_have_no_edited_content__After_publishing__fans_will_see_a_blank_page__It_is_recommended_to_add_and_edit_blocks_before_publishing_: `You currently have no edited content. After publishing, fans will see a blank page. It is recommended to add and edit blocks before publishing.`,
  Go_to_add_blocks: `Go to add blocks`,
  Custom_domain_name: `Custom domain name`,
  If_you_change_your_domain_name__you_ll_have_to_wait_30_days_for_the_next_change_: `If you change your domain name, you'll have to wait 30 days for the next change.`,
  The_domain_name_must_start_with_a_letter_and_only_contain_letters__numbers__and_underscores_____: `The domain name must start with a letter and only contain letters, numbers, and underscores "_".`,
  This_domain_name_is_already_exists__please_choose_another_one_: `The link name is already exists, please choose another one.`,
  Domain_name_cannot_be_longer_than_50_characters: `Domain name cannot be longer than 50 characters`,
  Continue_editting: `Continue editting`,
  Exit: `Exit`,
  Save: `Save`,
  Copy_successfully: `Copy successfully`,
  Save_successfully: `Save successfully`,
  Select_Block_Type: `Select Block Type`,
  Bio: `Bio`,
  Used_to_display_your_profile_picture__bio_name__bio_text_and_your_social_links_: `Show your profile picture, bio name, bio text and your social links.`,
  Image_Image_Gallery: `Image Gallery`,
  Add_single_multiple_images_to_your_site_: `Add single/multiple images to your bio page.`,
  Lazada_Affiliate: `Lazada Affiliate`,
  Recommend_affiliate_products_to_your_fans_: `Recommend affiliate products to your fans.`,
  Tip_from_fans: `Tip from fans`,
  Receive_tips_from_your_fans_: `Receive tips from your fans.`,
  Your_name: `Your name`,
  Edit_Bio_text: `Edit Bio text`,
  Grayed_logo_will_not_be_displayed_to_your_fans_Click_to_add_social_link_for_the_logo_: `Grayed logo will not be displayed to your fans.
Click to add social link for the logo.`,
  Edit_Name: `Edit Name`,
  Display_Name: `Display Name`,
  Please_enter: `Please enter`,
  No_more_than_50_characters: `No more than 50 characters`,
  Upload_Image: `Upload Image`,
  Support__JPG__JPEG__GIF__PNG__BMP__WebP__up_to_30MB: `Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 20MB`,
  Use_Image: `Use Image`,
  No_more_than_500_characters: `No more than 500 characters`,
  Edit_Bio_text: `Edit Bio text`,
  Are_you_sure_you_want_to_delete_this_social_network_: `Are you sure you want to delete this social network?`,
  Confirm: `Confirm`,
  Cancel: `Cancel`,
  Delete_successfully: `Delete successfully`,
  Edit_Social_Networks: `Edit Social Networks`,
  Select_an_icon: `Select an icon`,
  Select_link_type: `Select link type`,
  Enter_URL: `Enter URL`,
  Enter_phone_number: `Enter phone number`,
  Enter_email: `Enter email`,
  Add_Social_Network: `Add Social Network`,
  Add_Image_Image_Gallery: `Add Image/Image Gallery`,
  Step_1__Upload_Image: `Step 1: Upload Image`,
  __Add: `+ Add`,
  Next: `Next`,
  The_images_you_upload_will_not_be_saved_: `The images you upload will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Step_2__Add_Image_Description__Optional_: `Step 2: Add Image Description (Optional)`,
  Previous: `Previous`,
  Next_skip_this_step_: `Next(skip this step)`,
  The_content_you_edit_in_this_step_and_the_images_uploaded_in_the_previous_step_will_not_be_saved_: `The content you edit in this step and the images uploaded in the previous step will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Step_3__Add_link_for_Images__Optional_: `Step 3: Add link for Images (Optional)`,
  Done__skip_this_step_: `Done (skip this step)`,
  The_content_you_edit_in_this_step_and_the_data_from_the_previous_2_steps_will_not_be_saved_: `The content you edit in this step and the data from the previous 2 steps will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Image_block_title: `Image block title`,
  Image_block_description___: `Image block description...`,
  Click_to_add_image_description__optional_: `Click to add image description (optional)`,
  Image_descriptions_for_each_image_should_not_exceed_50_characters_: `Image descriptions for each image should not exceed 50 characters.`,
  Add_Affiliate_Products: `Add Affiliate Products`,
  Before_adding_affiliate_products_to_the_link__you_need_to_add_products_to_your_wishlist_first_: `Before adding affiliate products to the link, you need to add products to your wishlist first.`,
  The_wishlist_is_empty_now_: `The wishlist is empty now.`,
  Go_to_the_homepage_to_: `Go to the homepage to `,
  add_products_to_wishlist__: `add products to wishlist..`,
  Jump_to_the_homepage_shortly_: `Jump to the homepage shortly.`,
  Back_to_edit: `Back to edit`,
  Please_select_products_that_already_added_to_wishlist_: `Please select products that already added to wishlist.`,
  Product_List: `Product List`,
  Selected___products: `Selected * products`,
  Confirm: `Confirm`,
  Select: `Select`,
  Import_Products: `Import Products`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Up to 50 products can be imported under each product group.`,
  Product_block_title: `Product block title`,
  Product_block_description: `Product block description`,
  Search_product_by_name_: `Search product by name…`,
  __Add: `+ Add`,
  Youpik_platform_will_deduct_3__service_fee__Ex_VAT__from_fan_tips__If_you_need_an_invoice_for_this_service_fee__you_can_download_it_in__Account___My_Service___Tip_History__after_getting_fan_tips_: `Youpik platform will deduct 3% service fee (Ex-VAT) from fan tips. If you need an invoice for this service fee, you can download it in "Account - My Service - Tip History" after getting fan tips.`,
  Confirm_to_add: `Confirm to add`,
  Added_successfully: `Added successfully`,
  The__User_Agreement__has_been_updated__: `The "User Agreement" has been updated. `,
  Click__Agree__to_continue_using_: `Click "Agree" to continue using.`,
  Agree: `Agree`,
  Do_it_later: `Do it later`,
  Update_successfully: `Update successfully`,
  Added: `Added`,
  Your_current_changes_will_not_be_saved_: `Your current changes will not be saved.`,
  Are_you_sure_you_want_to_re_edit_: `Are you sure you want to re-edit?`,
  Confirm_re_edit: `Confirm re-edit`,
  Cancel_re_edit: `Cancel re-edit`,
  You_are_about_to_exit_: `You are about to exit.`,
  It_s_recommended_to_save_your_current_edits_before_leaving_: `It's recommended to save your current edits before leaving.`,
  Save_and_Publish: `Save and Publish`,
  Still_leave: `Still leave`,
  You_have_successfully_published_your_site_You_can_access_it_through_the_following_url_: `You have successfully published your site.You can access it through the following url.`,
  Your_site_url: `Your site url`,
  Go_to_customize_the_site: `Go to customize the site`,
  View_site_in_browser: `View site in browser`,
  Share: `Share`,
  Comfirm_to_delete_this_block_: `Comfirm to delete this block?`,
  Confirm_delete_: `Confirm delete?`,
  Comfirm_to_delete_this_product_group_and_all_products_under_it_: `Comfirm to delete this product group and all products under it?`,
  Product_group_name: `Product group name`,
  Add_failed: `Add failed`,
  Give_tip_to____: `Give tip to ***`,
  Be_the_first_to_give_tip_to______: `Be the first to give tip to ****!`,
  the_person: `the person`,
  See_More: `See More`,
  _404___This_page_is_not_be_found_: `404 | This page is not be found.`,
  Preview_mode: `Preview mode`,
  Welcome_to_Youpik_show: `Welcome to Youpik.show`,
  Start_by_following_our_tutuorial_to_experience_the_functions_of_Youpik_show__: `Start by following our tutuorial to experience the functions of Youpik.show !`,
  Get_Started: `Get Started`,
  Add_a_new_block: `Add a new block`,
  Several_Block_types_ready_to_add_to_your_site__help_you_create_unique_bio_page__: `Several Block types ready to add to your site, help you create unique bio page. `,
  Skip: `Skip`,
  Quick_Actions: `Quick Actions`,
  Click_to__Move_up__Move_down__Delete__blocks_: `Click to “Move up”“Move down”“Delete” blocks.`,
  And_you_can_also_drag_to_adjust_the_order_by_long_press_the_up_left_button_in_every_block_: `And you can also drag to adjust the order by long press the up-left button in every block.`,
  Edit_the_block: `Edit the block`,
  There_is_an_edit_button_in_the_upper_right_corner_of_every_block__click_to_enter_the_edit_page_to_edit_more_details_: `There is an edit button in the upper right corner of every block, click to enter the edit page to edit more details.`,
  Site_Setting: `Site Setting`,
  You_can_set_up_your_personal_domain_name_for_your_site_here_: `You can set up your personal domain name for your site here.`,
  Block_theme_settings: `Block theme settings`,
  Click_to_customize_the_theme_settings_here__These_settings_will_apply_to_your_entire_site_: `Click to customize the theme settings here. These settings will apply to your entire site.`,
  Restore_editting: `Restore editting`,
  if_you_are_not_satisfied_with_the_changes_you_made__you_can_use_this_function__The_system_will_restore_your_Design_screen_back_to_the_site_version_that_was_publishing_: `if you are not satisfied with the changes you made, you can use this function. The system will restore your Design screen back to the site version that was publishing.`,
  Using_template: `Using template`,
  Replace_your_entire_page_design_with_the_template_design_: `Replace your entire page design with the template design.`,
  If_you_don_t_add_any_block__you_can_also_use_the_template_design_and_data_: `If you don’t add any block, you can also use the template design and data.`,
  User_Guide: `User Guide`,
  you_can_review_the_user_guide_at_any_time_by_clicking_this_button_: `you can review the user guide at any time by clicking this button.`,
  Preview_site: `Preview site`,
  Preview_the_site_to_see_the_current_changes_of_site_Publish_should_only_be_made_once_you_have_previewed_and_are_satisfied_with_the_changes_: `Preview the site to see the current changes of site.
Publish should only be made once you have previewed and are satisfied with the changes.`,
  Publish_design: `Publish design`,
  Design_edits_are_always_in__Draft__mode__The_changes_will_be_visible_to_your_fans_once_you_decide_to__Publish__: `Design edits are always in “Draft” mode. The changes will be visible to your fans once you decide to “Publish”.`,
  Block_Style_Settings: `Block Style Settings`,
  Square: `Square`,
  Rounded: `Rounded`,
  Custom: `Custom`,
  Block_s_padding: `Block's padding`,
  Radius: `Radius`,
  Block_s_margin: `Block's margin`,
  Spacing_between_blocks: `Spacing between blocks`,
  Block_Background_color: `Block Background color`,
  Block_Border: `Block Border`,
  Block_Border_Color: `Block Border Color`,
  Border_Color_Style: `Border Color Style`,
  Solid: `Solid`,
  Dot: `Dot`,
  Dash: `Dash`,
  Block_Border_width_px_: `Block Border width(px)`,
  Site_page_background_setting: `Site page background setting`,
  Color: `Color`,
  //第一期
  Blur_bg: `Blur`,
  View_Template: `View Template`,
  Preview_with_Template: `Preview with Template`,
  your_name: `your name`,
  your_bio_text: `your bio text`,
  Apply_Template_Design_and_Data: `Apply Template Design and Data`,
  Do_you_want_to_apply_the_template_: `Do you want to apply the template?`,
  Applying_the_template_will_replace_all_your_edited_designs_and_data_with_the_template_s_designs_and_data_: `Applying the template will replace all your edited designs and data with the template's designs and data.`,
  Confirm: `Confirm`,
  Applied_Successfully: `Applied Successfully`,
  Apply_Template_Design: `Apply Template Design`,
  Applying_the_template_will_replace_all_your_block_designs_with_the_template_s_designs__while_your_data_remains_unchanged_: `Applying the template will replace all your block designs with the template's designs, while your data remains unchanged.`,
  Profile_Picture: `Head portrait`,
  Bio_Name: `Bio Name`,
  Bio_text: `Bio text`,
  List_of_Social_Networks: `List of Social Networks`,
  Add: `Add`,
  Social_icons_without_setting_links_will_appear_grayed_out_in_the_design_homepage_and_not_displayed_in_the_preview_: `Social icons without setting links will appear grayed-out in the design homepage and not displayed in the preview.`,
  Confirm_to_delete_this_social_network_: `Confirm to delete this social network?`,
  Enter_your_social_network___: `Enter your social network...`,
  Layout: `Layout`,
  Layout_1: `Layout 1`,
  Layout_2: `Layout 2`,
  Layout_3: `Layout 3`,
  Layout_4: `Layout 4`,
  Cancel_editting_: `Cancel editting?`,
  Your_modifications_will_not_be_saved_: `Your modifications will not be saved.`,
  Are_you_sure_you_want_to_exit_: `Are you sure you want to exit?`,
  Note__This_preview_state_is_what_fans_will_see_: `Note: This preview state is what fans will see.`,
  The_preview_and_fan_side_will_not_see_grayed_out_social_icons_without_setting_social_networks_: `The preview and fan side will not see grayed-out social icons without setting social networks.`,
  Image_block_title_optional_: `Image block title(optional)`,
  Image_block_description_optional_: `Image block description(optional)`,
  Image_List: `Image List`,
  Confirm_to_delete_this_image_: `Confirm to delete this image?`,
  The_image_list_is_empty_: `The image list is empty.`,
  To_add_image_s___click_the__Add__button_above_: `To add image(s), click the "Add" button above.`,
  Image_Details_Page: `Image Details Page`,
  Image: `Image`,
  Image_Description: `Image Description`,
  Link: `Link`,
  Select_link_type: `Select link type`,
  Style: `Style`,
  Grid: `Grid`,
  Slide: `Slide`,
  Number_of_Images_per_Row: `Number of Images per Row`,
  Slider_Type: `Slider Type`,
  Partial_Visible: `Partial Visible`,
  Panorama: `Panorama`,
  Manual_Slide: `Manual Slide`,
  Auto_play: `Auto play`,
  Auto_play_Interval: `Auto play Interval`,
  Number_of_Products_per_Row: `Number of Products per Row`,
  Product_Group_List: `Product Group List`,
  Select: `Select`,
  products: `products`,
  Hide_rest_products_in_every_group: `Hide rest products in every group`,
  Hide: `Hide`,
  Expand_to_Show_All: `Expand to Show All`,
  Rename: `Rename`,
  Delete: `Delete`,
  Move_to_the_bottom: `Move to the bottom`,
  Move_to_the_top: `Move to the top`,
  Product_group_name: `Product group name`,
  Add_product_group: `Add product group`,
  All_products_from_your_wishlist_have_been_placed_in_other_product_groups_: `All products from your wishlist have been placed in other product groups.`,
  Go_to_the_homepage_to_: `Go to the homepage to `,
  add_products_to_wishlist__: `add products to wishlist..`,
  Product_Edit_Page: `Product Edit Page`,
  Select_product_group: `Select product group`,
  Product_Import_Page: `Product Import Page`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Up to 50 products can be imported under each product group.`,
  Selected___product_groups: `Selected * product groups`,
  Confirm_to_delete_the_selected___product_groups_and_all_products_under_them_: `Confirm to delete the selected * product groups and all products under them?`,
  Delete_failed: `Delete failed`,
  Confirm_to_delete_this_product_: `Confirm to delete this product?`,
  Change_product_group: `Change product group`,
  Move_Product: `Move Product`,
  Move___product_to: `Move * product to`,
  Move_product_to_____successfully: `Move product to *** successfully`,
  Move___products_to_____successfully: `Move * products to *** successfully`,
  Each_product_group_supports_importing_up_to_50_products_: `Each product group supports importing up to 50 products.`,
  This_product_group_already_has___products_: `This product group already has * products,`,
  You_can_move_no_more_than___products_: `You can move no more than * products.`,
  Move_to: `Move to`,
  Each_product_group_supports_importing_up_to_50_products_: `Each product group supports importing up to 50 products.`,
  This_product_group_already_has___products_: `This product group already has * products,`,
  You_can_import_no_more_than___products_: `You can import no more than * products.`,
  If_you_don_t_want_to_add_any_of_the_above__go_to_the_homepage_to_select_new_products_to_add_to_the_wishlist_: `If you don't want to add any of the above, go to the homepage to select new products to add to the wishlist.`,
  Save_switch: `Save&switch`,
  Discard_edit_switch: `Discard edit&switch`,
  Product_Details: `Product Details`,
  last_30_days: `last 30 days`,
  Click_the_button_below_to_know_more_about_product_details: `Click the button below to know more about product details`,
  Buy_Now: `Buy Now`,
  Confirm_to_give_tips_to______: `Confirm to give tips to ****?`,
  Tip_Amounts: `Tip Amounts`,
  Please_enter_the_number_: `Please enter the number.`,
  whether_to_be_anonymous: `whether to be anonymous`,
  Anonymous: `Anonymous`,
  Please_enter_your_name_: `Please enter your name.`,
  Leave_the_message_for_the_creator_optional_: `Leave the message for the creator(optional)`,
  Tip_Information: `Tip Information`,
  Tip_to: `Tip to`,
  Select_Payment_Methods: `Select Payment Methods`,
  Total_Amounts: `Total Amounts`,
  youpik_show: `youpik.show`,
  order_from__page: `order from: page`,
  Tip_from_fans: `Tip from fans`,
  Jump_to_the_homepage_shortly_: `Jump to the homepage shortly.`,
  We_recommend_saving_all_data_on_the_Lazada_Affiliate_product_edit_page_before_jumping_: `We recommend saving all data on the Lazada Affiliate product edit page before jumping.`,
  Discard_and_jump: `Discard and jump`,
  Save_and_jump: `Save and jump`,
  Hot_sale_products: `Hot sale products`,
  Popular_products: `Popular products`,
  Please_enter_correct_link: `Please enter correct link`,
  '《用户协议》已更新，点击同意后继续使用': `The "《User Agreement》" has been updated. Click "Agree" to continue using.`,
  Please_enter_correct_E_mail: `Please enter correct E-mail`
}

export const bypTh = {
  No_tip_invoices_generated_at_the_moment_: `ไม่มีอินวอยซ์`,
  Use_Template_: `ตัวอย่าง`,
  Guide: `คู่มือ`,
  Preview: `แสดงตัวอย่าง`,
  Publish: `เผยแพร่`,
  No_block: `ไม่มีบล็อก`,
  No_block_has_been_added_yet: `ยังไม่มีการเพิ่มบล็อกใด ๆ ในขณะนี้`,
  Add_block: `เพิ่มบล็อก`,
  Custom_domain: `กำหนดโดเมน`,
  Block_theme_Settings: `การตั้งค่าธีมบล็อก`,
  Re_edit: `แก้ไขอีกครั้ง`,
  Please_add_blocks_before_publishing_: `โปรดเพิ่มบล็อกก่อนการเผยแพร่!`,
  You_currently_have_no_edited_content__After_publishing__fans_will_see_a_blank_page__It_is_recommended_to_add_and_edit_blocks_before_publishing_: `ขณะนี้คุณยังไม่มีเนื้อหาที่แก้ไข หลังจากเผยแพร่แล้ว ผู้ติดตามของคุณจะเห็นหน้าเปล่า แนะนำให้เพิ่มและแก้ไขบล็อกก่อนการเผยแพร่`,
  Go_to_add_blocks: `เพิ่มบล็อกของคุณ`,
  Custom_domain_name: `กำหนดชื่อโดเมน`,
  If_you_change_your_domain_name__you_ll_have_to_wait_30_days_for_the_next_change_: `หากคุณเปลี่ยนชื่อโดเมน คุณจะต้องรอ 30 วันสำหรับการเปลี่ยนแปลงครั้งถัดไป`,
  The_domain_name_must_start_with_a_letter_and_only_contain_letters__numbers__and_underscores_____: `ชื่อโดเมนจะต้องขึ้นต้นด้วยตัวอักษรและตัวเลข ห้ามใช้อักขระ`,
  This_domain_name_is_already_exists__please_choose_another_one_: `ชื่อลิงก์นี้มีอยู่แล้ว โปรดใช้ชื่ออื่น`,
  Domain_name_cannot_be_longer_than_50_characters: `ชื่อโดเมนไม่สามารถยาวเกิน 50 ตัวอักษร`,
  Your_modifications_will_not_be_saved_: `การแก้ไขของคุณจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_exit_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Continue_editting: `ดำเนินการแก้ไขต่อ`,
  Exit: `ออก`,
  Save: `บันทึก`,
  Copy_successfully: `คัดลอกสำเร็จ`,
  Save_successfully: `บันทึกสำเร็จ`,
  Select_Block_Type: `เลือกประเภทบล็อก`,
  Bio: `Bio`,
  Used_to_display_your_profile_picture__bio_name__bio_text_and_your_social_links_: `ใช้สำหรับแสดงรูปโปรไฟล์ ชื่อ bio และลิงก์โซเชียลของคุณ`,
  Image_Image_Gallery: `รูปภาพและคลังรูปภาพ`,
  Add_single_multiple_images_to_your_site_: `เพิ่มรูปภาพเข้าเว็บไซต์ของคุณ`,
  Lazada_Affiliate: `Lazada Affiliate`,
  Recommend_affiliate_products_to_your_fans_: `แนะนำสินค้า Affiliate ให้กับผู้ติดตามของคุณ`,
  Tip_from_fans: `รางวัลจากผู้ติดตามของคุณ`,
  Receive_tips_from_your_fans_: `ได้รับรางวัลจากผู้ติดตามของคุณ`,
  Your_name: `ชื่อของคุณ`,
  Edit_Bio_text: `แก้ไข Bio ของคุณ`,
  Grayed_logo_will_not_be_displayed_to_your_fans_Click_to_add_social_link_for_the_logo_: `คลิกที่โลโก้เพื่อเพิ่มช่องทางในการแชร์เพจของคุณ`,
  Edit_Name: `แก้ไขชื่อ`,
  Display_Name: `ชื่อที่แสดง`,
  Please_enter: `กดที่นี่`,
  No_more_than_50_characters: `ห้ามเกิน 50 ตัวอักษร`,
  Upload_Image: `อัปโหลดรูปภาพ`,
  Support__JPG__JPEG__GIF__PNG__BMP__WebP__up_to_30MB: `รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP ขนาดไม่เกิน 20MB`,
  Use_Image: `ใช้รูปภาพ`,
  No_more_than_500_characters: `ไม่เกิน 500 ตัวอักษร`,
  Edit_Bio_text: `แก้ไข Bio ของคุณ`,
  Are_you_sure_you_want_to_delete_this_social_network_: `คุณแน่ใจหรือไม่ว่าต้องการลบ Social Networks`,
  Confirm: `ยืนยัน`,
  Cancel: `ยกเลิก`,
  Delete_successfully: `ลบสำเร็จ`,
  Edit_Social_Networks: `แก้ไข Social Networks`,
  Select_an_icon: `เลือกไอคอน`,
  Select_link_type: `เลือกประเภทลิงก์`,
  Enter_URL: `ใส่ URL`,
  Enter_phone_number: `ใส่หมายเลขโทรศัพท์`,
  Enter_email: `ใส่อีเมล`,
  Add_Social_Network: `ใส่ Social Networks`,
  Add_Image_Image_Gallery: `เพิ่มรูปภาพและอัลบั้มรูปภาพ`,
  Step_1__Upload_Image: `ขั้นตอนที่ 1: อัปโหลดรูปภาพ`,
  __Add: `เพิ่ม`,
  Next: `ถัดไป`,
  The_images_you_upload_will_not_be_saved_: `รูปภาพที่คุณอัปโหลดจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Step_2__Add_Image_Description__Optional_: `ขั้นตอนที่ 2: เพิ่มคำอธิบายรูปภาพ (ไม่บังคับ)`,
  Previous: `ย้อนกลับ`,
  Next_skip_this_step_: `ถัดไป (ข้ามขั้นตอนนี้)`,
  The_content_you_edit_in_this_step_and_the_images_uploaded_in_the_previous_step_will_not_be_saved_: `เนื้อหาที่คุณแก้ไขในขั้นตอนนี้และรูปภาพที่อัปโหลดในขั้นตอนก่อนหน้าจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Step_3__Add_link_for_Images__Optional_: `ขั้นตอนที่ 3: เพิ่มลิงก์สำหรับรูปภาพ (ไม่บังคับ)`,
  Done__skip_this_step_: `เสร็จสิ้น (ข้ามขั้นตอนนี้)`,
  The_content_you_edit_in_this_step_and_the_data_from_the_previous_2_steps_will_not_be_saved_: `เนื้อหาที่คุณแก้ไขในขั้นตอนนี้ และข้อมูลจากขั้นตอนก่อนหน้านี้จะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Image_block_title: `ชื่อรูปภาพ`,
  Image_block_description___: `คำบรรยายรูปภาพ`,
  Click_to_add_image_description__optional_: `คลิกเพื่อเพิ่มคำบรรยายรูปภาพ (ไม่บังคับ)`,
  Image_descriptions_for_each_image_should_not_exceed_50_characters_: `คำบรรยายรูปภาพห้ามเกิน 50 ตัวอักษร`,
  Add_Affiliate_Products: `เพิ่มสินค้า Affiliate`,
  Before_adding_affiliate_products_to_the_link__you_need_to_add_products_to_your_wishlist_first_: `ก่อนเพิ่มสินค้า Affiliate คุณต้องเพิ่มสินค้าลงรายการโปรดก่อน`,
  The_wishlist_is_empty_now_: `ไม่มีรายการโปรด`,
  Go_to_the_homepage_to_: `ไปที่หน้าหลักเพื่อ`,
  add_products_to_wishlist__: `เพิ่มสินค้าไปที่รายการโปรด`,
  Jump_to_the_homepage_shortly_: `กำลังไปหน้าหลัก`,
  Back_to_edit: `กลับไปแก้ไข`,
  Please_select_products_that_already_added_to_wishlist_: `โปรดเลือกสินค้าจากในรายการโปรด`,
  Product_List: `รายการสินค้า`,
  Selected___products: `สินค้าที่เลือก`,
  Confirm: `ยืนยัน`,
  Select: `เลือก`,
  Import_Products: `อัปโหลดสินค้า`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `แต่ละรายการสามารถอัปโหลดสินค้าได้สูงสุด 50 รายการ`,
  Product_block_title: `ชื่อบล็อกสินค้า`,
  Product_block_description: `คำอธิบายบล็อกสินค้า`,
  Search_product_by_name_: `ค้นหาชื่อสินค้า`,
  __Add: `เพิ่ม`,
  Youpik_platform_will_deduct_3__service_fee__Ex_VAT__from_fan_tips__If_you_need_an_invoice_for_this_service_fee__you_can_download_it_in__Account___My_Service___Tip_History__after_getting_fan_tips_: `Youpik จะหักค่าบริการ 3% (ยกเว้นภาษีมูลค่าเพิ่ม) จากรางวัลที่ได้รับ หากคุณต้องการอินวอยซ์สำหรับค่าบริการ คุณสามารถดาวน์โหลดได้ที่ "บัญชี - บริการของฉัน - ประวัติการรับรางวัล" หลังจากที่ได้รับรางวัลจากผู้ติดตาม`,
  Confirm_to_add: `ยืนยันการเพิ่ม`,
  Added_successfully: `เพิ่มสำเร็จ`,
  The__User_Agreement__has_been_updated__: `"ข้อตกลงผู้ใช้" ได้รับการอัปเดต`,
  Click__Agree__to_continue_using_: `คลิก "ยอมรับ" เพื่อดำเนินการใช้งานต่อ`,
  Agree: `ยอมรับ`,
  Do_it_later: `ทำในภายหลัง`,
  Update_successfully: `อัปเดตเรียบร้อยแล้ว`,
  Added: `เพิ่มแล้ว`,
  Your_current_changes_will_not_be_saved_: `การเปลี่ยนแปลงปัจจุบันของคุณจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_re_edit_: `คุณแน่ใจหรือไม่ว่าต้องการแก้ไขอีกครั้ง`,
  Confirm_re_edit: `ยืนยันการแก้ไข`,
  Cancel_re_edit: `ยกเลิกการแก้ไข`,
  You_are_about_to_exit_: `หากคุณต้องการที่จะออกจากหน้านี้ `,
  It_s_recommended_to_save_your_current_edits_before_leaving_: `กรุณาบันทึกการแก้ไขก่อนออก`,
  Save_and_Publish: `บันทึกและเผยแพร่`,
  Still_leave: `ไม่บันทึก`,
  You_have_successfully_published_your_site_You_can_access_it_through_the_following_url_: `คุณได้เผยแพร่เพจของคุณเรียบร้อยแล้ว สามารถเข้าได้ทาง URL`,
  Your_site_url: `URL ของคุณ`,
  Go_to_customize_the_site: `ปรับแต่งเพจ`,
  View_site_in_browser: `ดูเว็บไซต์ในเบราว์เซอร์`,
  Share: `แชร์`,
  Comfirm_to_delete_this_block_: `ยืนยันการลบบล็อกนี้หรือไม่`,
  Confirm_delete_: `ยืนยันการลบใช่หรือไม่`,
  Comfirm_to_delete_this_product_group_and_all_products_under_it_: `ยืนยันการลบกลุ่มสินค้านี้หรือไม่`,
  Product_group_name: `ชื่อกลุ่มสินค้า`,
  Add_failed: `เพิ่มไม่สำเร็จ`,
  Give_tip_to____: `ให้รับรางวัลกับ`,
  Be_the_first_to_give_tip_to______: `เป็นคนแรกที่ให้รางวัลกับ ****!`,
  the_person: `ผู้ติดตาม`,
  See_More: `ดูเพิ่ม`,
  _404___This_page_is_not_be_found_: `ไม่มีหน้านี้`,
  Preview_mode: `โหมดแสดงตัวอย่าง`,
  Welcome_to_Youpik_show: `ยินดีต้อนรับสู่ Youpik.show`,
  Start_by_following_our_tutuorial_to_experience_the_functions_of_Youpik_show__: `เพื่อการใช้งานที่ง่ายขึ้น เพียงแค่ทำตามคำแนะนำการใช้งานตามนี้`,
  Get_Started: `เริ่มเลย!`,
  Add_a_new_block: `เพิ่มบล็อก`,
  Several_Block_types_ready_to_add_to_your_site__help_you_create_unique_bio_page__: `คุณสามารถสร้างบล็อกบนเพจของคุณได้เลย`,
  Skip: `ข้าม`,
  Quick_Actions: `เพื่อการใช้ที่รวดเร็วขึ้น`,
  Click_to__Move_up__Move_down__Delete__blocks_: `คลิกเพื่อ "เลื่อนขึ้น" "เลื่อนลง" "ลบบล็อก"`,
  And_you_can_also_drag_to_adjust_the_order_by_long_press_the_up_left_button_in_every_block_: `และคุณยังสามารถลากเพื่อปรับลำดับโดยการกดค้างปุ่มด้านบนซ้ายในทุกบล็อก`,
  Edit_the_block: `กรแก้ไขบล็อก`,
  There_is_an_edit_button_in_the_upper_right_corner_of_every_block__click_to_enter_the_edit_page_to_edit_more_details_: `มีปุ่มแก้ไขอยู่ในมุมขวาบนของทุกบล็อก คลิกเพื่อเข้าสู่หน้าแก้ไขเพื่อแก้ไขรายละเอียด`,
  Site_Setting: `ตั้งค่าเพจ`,
  You_can_set_up_your_personal_domain_name_for_your_site_here_: `คุณสามารถตั้งค่าชื่อเพจของคุณได้ที่นี้`,
  Block_theme_settings: `ตั้งค่าธีมบล็อก`,
  Click_to_customize_the_theme_settings_here__These_settings_will_apply_to_your_entire_site_: `คลิกเพื่อปรับแต่งการตั้งค่าธีมที่นี่ การตั้งค่าจะถูกใช้กับเพจของคุณ`,
  Restore_editting: `ล้างการแก้ไข`,
  if_you_are_not_satisfied_with_the_changes_you_made__you_can_use_this_function__The_system_will_restore_your_Design_screen_back_to_the_site_version_that_was_publishing_: `หากคุณต้องการยกเลิกการแก้ไข คุณสามารถเลือก "ล้างการแก้ไข" เพื่อเรียกคืนธีมเดิมของเพจ`,
  Using_template: `เทมเพลต`,
  Replace_your_entire_page_design_with_the_template_design_: `ถ้าเพจของคุณยังไม่มีบล็อกหรือยังไม่ได้ใช้เทมเพล`,
  If_you_don_t_add_any_block__you_can_also_use_the_template_design_and_data_: `ต ระบบจะตั้งค่าเพจของคุณเป็นเทมเพลตเริ่มต้น `,
  User_Guide: `คู่มือ`,
  you_can_review_the_user_guide_at_any_time_by_clicking_this_button_: `หากมีข้อสงสัยเกี่ยวกับการใช้งาน คุณสามารถมาที่ "คู่มือ" ได้ตลอดเวลา`,
  Preview_site: `ดูตัวอย่าง`,
  Preview_the_site_to_see_the_current_changes_of_site_Publish_should_only_be_made_once_you_have_previewed_and_are_satisfied_with_the_changes_: `คุณสามารถดูตัวอย่าง ก่อนการเผยแพร่เพจ`,
  Publish_design: `เผยแพร่`,
  Design_edits_are_always_in__Draft__mode__The_changes_will_be_visible_to_your_fans_once_you_decide_to__Publish__: `การแก้ไขการออกแบบจะอยู่ในโหมด "ร่าง" เสมอ และจะแสดงให้ผู้ติดตามเห็นต่อเมื่อคุณเผยแพร่ `,
  Block_Style_Settings: `ตั้งค่ารูปแบบบล็อก`,
  Square: `สี่เหลี่ยม`,
  Rounded: `มุมโค้ง`,
  Custom: `กำหนดเอง`,
  Block_s_padding: `ช่องว่างภายในบล็อก`,
  Radius: `รัศมี`,
  Block_s_margin: `ขอบของบล็อก`,
  Spacing_between_blocks: `ระยะห่างระหว่างบล็อก`,
  Block_Background_color: `สีพื้นหลั`,
  Block_Border: `ขอบของบล็อก`,
  Block_Border_Color: `สีของขอบบล็อก`,
  Border_Color_Style: `สไตล์สีขอบ`,
  Solid: `ขนาดเส้น`,
  Dot: `จุด`,
  Dash: `เส้นประ`,
  Block_Border_width_px_: `ความกว้างของเส้นขอบบล็อก (px)`,
  Site_page_background_setting: `การตั้งค่าพื้นหลังของเพจ`,
  Color: `สี`,
  Blur: `ความมัวของภาพ`,
  View_Template: `ดูเทมเพลต`,
  Preview_with_Template: `ดูตัวอย่าง`,
  your_name: `ชื่อ`,
  your_bio_text: `Bio`,
  Apply_Template_Design_and_Data: `ใส่เทมเพลตและข้อมูลของคุณ`,
  Do_you_want_to_apply_the_template_: `คุณต้องการที่จะใช้เทมเพลตนี้หรือไม่`,
  Applying_the_template_will_replace_all_your_edited_designs_and_data_with_the_template_s_designs_and_data_: `หากคุณต้องการใช้เทมเพลตนี้ การออกแบบเพจก่อนหน้าจะหายไป`,
  Confirm: `ยืนยัน`,
  Applied_Successfully: `ใช้งานเทมเพลตสำเร็จ`,
  Apply_Template_Design: `ใช้งานเทมเพลต`,
  Applying_the_template_will_replace_all_your_block_designs_with_the_template_s_designs__while_your_data_remains_unchanged_: `หลังจากใช้งานเทมเพลตแล้ว เทมเพลตก่อนหน้าจะถูกแทนที่แต่ข้อมูลยังเหมือนเดิม`,
  Profile_Picture: `รูปโปรไฟล์`,
  Bio_Name: `Bio`,
  Bio_text: `ข้อความ Bio`,
  List_of_Social_Networks: `Social Networks`,
  Add: `เพิ่ม`,
  Social_icons_without_setting_links_will_appear_grayed_out_in_the_design_homepage_and_not_displayed_in_the_preview_: `ช่องทางโซเชียลที่คุณไม่ได้เลือกในการแชร์เพจ โลโก้จะแสดงเป็นสีเทาและไม่แสดงในหน้าตัวอย่าง`,
  Confirm_to_delete_this_social_network_: `คุณแน่ใจหรือไม่ว่าคุณต้องการ Social Networks หรือไม่`,
  Enter_your_social_network___: `ใส่ Social Networks ของคุณ`,
  Layout: `เค้าโครง`,
  Layout_1: `เค้าโครง 1`,
  Layout_2: `เค้าโครง 2`,
  Layout_3: `เค้าโครง 3`,
  Layout_4: `เค้าโครง 4`,
  Cancel_editting_: `ยกเลิกการแก้ไขหรือไม่`,
  Note__This_preview_state_is_what_fans_will_see_: `หมายเหตุ: ตัวอย่างที่ผู้ติดตามคุณจะเห็น`,
  The_preview_and_fan_side_will_not_see_grayed_out_social_icons_without_setting_social_networks_: `หากไม่ได้ตั้งค่า Social Networks ผู้ติดตามของคุณจะไม่เห็นไอคอนนี้`,
  Image_block_title_optional_: `ชื่อรูปภาพของบล็อก (ไม่บังคับ)`,
  Image_block_description_optional_: `คำบรรยายรูปภาพของบล็อก (ไม่บังคับ)`,
  Image_List: `รายการรูปภาพ`,
  Confirm_to_delete_this_image_: `ยืนยันที่จะลบรูปภาพ`,
  The_image_list_is_empty_: `ยังไม่มีรูปภาพ`,
  To_add_image_s___click_the__Add__button_above_: `คลิกปุ่มด้านบนเพื่อเพิ่มรูปภาพ`,
  Image_Details_Page: `หน้ารายละเอียดของรูปภาพ`,
  Image: `รูปภาพ`,
  Image_Description: `คำบรรยายรูปภาพ`,
  Link: `ลิงก์`,
  Select_link_type: `เลือกประเภทลิงก์`,
  Style: `สไตล์`,
  Grid: `ตาราง`,
  Slide: `สไลด์`,
  Number_of_Images_per_Row: `จำนวนรูปภาพต่อแถว`,
  Slider_Type: `ประเภทของสไลด์`,
  Partial_Visible: `มองเห็นบางส่วน`,
  Panorama: `พาโนรามา`,
  Manual_Slide: `สไลด์ด้วยตนเอง`,
  Auto_play: `เล่นอัตโนมัติ`,
  Auto_play_Interval: `ระยะเวลาเล่นอัตโนมัติ`,
  Number_of_Products_per_Row: `จำนวนสินค้าในแต่ละแถว`,
  Product_Group_List: `รายการกลุ่มสินค้า`,
  Select: `เลือก`,
  products: `สินค้า`,
  Hide_rest_products_in_every_group: `ซ่อนสินค้าในทุกกลุ่ม`,
  Hide: `ซ่อน`,
  Expand_to_Show_All: `ขยายเพื่อแสดงทั้งหมด`,
  Rename: `เปลี่ยนชื่อ`,
  Delete: `ลบ`,
  Move_to_the_bottom: `ย้ายไปด้านล่าง`,
  Move_to_the_top: `ย้ายไปด้านบน`,
  Product_group_name: `ชื่อกลุ่มสินค้า`,
  Add_product_group: `เพิ่มกลุ่มสินค้า`,
  All_products_from_your_wishlist_have_been_placed_in_other_product_groups_: `สินค้าทั้งหมดจากรายการโปรดได้ย้ายไปยังหน้ากลุ่มสินค้าแล้ว`,
  Go_to_the_homepage_to_: `ไปที่หน้าหลัก`,
  add_products_to_wishlist__: `เพื่อเพิ่มสินค้าไปยังรายการโปรด`,
  Product_Edit_Page: `หน้าสำหรับแก้ไขสินค้า`,
  Select_product_group: `เลือกกลุ่มสินค้า`,
  Product_Import_Page: `หน้าสินค้าที่เลือก`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `สามารถใส่สินค้าในแต่ละกลุ่มสินค้าสูงสุดได้ 50 ชิ้น`,
  Selected___product_groups: `เลือก * กลุ่มสินค้า`,
  Confirm_to_delete_the_selected___product_groups_and_all_products_under_them_: `ยืนยันที่จะลบ`,
  Delete_failed: `การลบล้มเหลว`,
  Confirm_to_delete_this_product_: `ยืนยันที่จะลบสินค้า`,
  Change_product_group: `เปลี่ยนกลุ่มสินค้า`,
  Move_Product: `ย้ายสินค้า`,
  Move___product_to: `ย้าย * สินค้าไปยัง`,
  Move_product_to_____successfully: `ย้าย * สินค้าไปยัง *** เสร็จสิ้น`,
  Move___products_to_____successfully: `ย้าย * สินค้าไปยัง *** เสร็จสิ้น`,
  Each_product_group_supports_importing_up_to_50_products_: `แต่ละกลุ่มสินค้าสามารถเพิ่มสินค้าได้สูงสุด 50 รายการ`,
  This_product_group_already_has___products_: `สินค้านี้มีอยู่แล้ว *`,
  You_can_move_no_more_than___products_: `คุณสามารถเพิ่มสินค้าได้ไม่เกิน * ชิ้น`,
  Move_to: `ย้ายไปยัง`,
  Each_product_group_supports_importing_up_to_50_products_: `แต่ละกลุ่มสินค้าสามารถเพิ่มสินค้าได้สูงสุด 50 รายการ`,
  This_product_group_already_has___products_: `สินค้านี้มีอยู่แล้ว *`,
  You_can_import_no_more_than___products_: `คุณสามารถเพิ่มสินค้าได้ไม่เกิน * ชิ้น`,
  If_you_don_t_want_to_add_any_of_the_above__go_to_the_homepage_to_select_new_products_to_add_to_the_wishlist_: `หากไม่ต้องเพิ่มสินค้าแล้ว กลับสู่หน้าโฮมเพจเพื่อเลือกสินค้าเข้าไปในรายการโปรด`,
  Save_switch: `บันทึก`,
  Discard_edit_switch: `ยกเลิกการแก้ไข`,
  Product_Details: `รายละเอียดสินค้า`,
  last_30_days: `สำหรับรายละเอียสินค้า`,
  Click_the_button_below_to_know_more_about_product_details: `คลิกที่ปุ่มด้านล่าง`,
  Buy_Now: `ซื้อทันที`,
  Confirm_to_give_tips_to______: `ยืนยันที่จะรางวัลใ้กับ **** ใช่ไหม`,
  Tip_Amounts: `จำนวนรางวัล`,
  Please_enter_the_number_: `โปรดใส่จำนวนต้องที่การรางวัล`,
  whether_to_be_anonymous: `ต้องการระบุชื่อหรือไม่`,
  Anonymous: `นามแฝง`,
  Please_enter_your_name_: `ระบุชื่อของคุณ`,
  Leave_the_message_for_the_creator_optional_: `ฝากข้อความให้กับครีเอเตอร์คนโปรดของคุณ`,
  Tip_Information: `รายละเอียกการรางวัล`,
  Tip_to: `รางวัลให้กับ`,
  Select_Payment_Methods: `เลือกวิธีการชำระเงิน`,
  Total_Amounts: `ยอดเงินรวม`,
  youpik_show: `Youpik.show`,
  order_from__page: `คำสั่งซื้อจาก: หน้า`,
  Tip_from_fans: `รางวัลจากผู้ติดตาม`,
  Jump_to_the_homepage_shortly_: `กำลังไปหน้าหลักในไม่ช้านี้`,
  We_recommend_saving_all_data_on_the_Lazada_Affiliate_product_edit_page_before_jumping_: `กรุณาบันทึกก่อนที่จะไปยัง Lazada`,
  Discard_and_jump: `ไม่บันทึกและข้าม`,
  Save_and_jump: `บันทึกและข้าม`,
  Hot_sale_products: `สินค้าขายดี`,
  Popular_products: `สินค้ายอดนิยม`,
  Please_enter_correct_link: `กรุณาใส่ลิงก์ที่ถูกต้อง`,
  '《用户协议》已更新，点击同意后继续使用': `"《ข้อตกลงผู้ใช้》" ได้รับการอัปเดตคลิก "ยอมรับ" เพื่อดำเนินการใช้งานต่อ`,
  Please_enter_correct_E_mail: `กรุณากรอกอีเมลที่ถูกต้อง`
}
