import memberLevelTranslation from '../memberLevelTranslation'

export const lazadaEn = {
  // 每日推荐
  每日推荐: 'BEST DEALS',
  店铺好价: 'Brand Exclusive',
  类目精选: 'All Categories',
  下载登录Youpik: 'Register in  APP',
  '最高37%的cashback': 'UP TO 37% CASH BACK',
  立即注册: 'SIGN UP NOW',
  // 条件和条款
  'Youpik Terms and Conditions': 'Youpik Terms and Conditions',
  // 高佣合集
  高佣合集: 'High Commission',
  高佣店铺: 'HIGH COMM SHOP',
  高佣精选: 'Selected For You',
  省: memberLevelTranslation({
    Cashback: [0, 1],
    Earn: [2, 3]
  }),
  // dire
  '请阅读以下条款，以保证获得cashback':
    'In order to get the cash back, please read the clauses below carefully.',
  跳转: 'Jump',
  '1次记录1笔佣金': '1 jump with 1 order commission',
  支付: 'Payment',
  跳转24hr完成: 'within 24hr after jump',
  订单: 'Order',
  支付后48hr内查看: 'within 48hr after payment',
  结算: 'Cashback',
  订单完成后下周五: 'next Friday after order completed',
  DOs: 'DOs',
  'You must click through Youpik to Lazada every time you make a new purchase.':
    'You must click through Youpik to Lazada every time you make a new purchase.',
  'Ensure that the cart in Lazada is empty before clicking through Youpik.':
    'Ensure that the cart in Lazada is empty before clicking through Youpik.',
  'DON’Ts': 'DON’Ts',
  'Gift card purchases.': 'Gift card purchases.',
  'Returns, exchanges & cancellations.': 'Returns, exchanges & cancellations.',
  'Fraud orders (for instance, register multiple new Youpik accounts to order from Lazada or order from self-owned Lazada shop) are not accepted.':
    'Fraud orders (for instance, register multiple new Youpik accounts to order from Lazada or order from self-owned Lazada shop) are not accepted.',
  其他条款和条件: 'Other conditions and clauses',
  'Youpik宣传均为预估佣金，实际订单佣金%如有更改，恕不另行通知。':
    'Youpik promotions are all estimated commissions, and the actual order commission % is subject to change without prior notice.',
  更多详情请访问: 'For more details please read',
  'Youpik条款与条件。': 'Youpik Terms and Conditions',
  // 商品详情
  商品详情: 'Product Details',
  立即下单后升级L1可省: 'Order now and upgrade to L1 to save',
  预计省: 'Earn',
  点击获取: 'Click to EARN',
  本单预计节省: 'Earn',
  // /更换样式app翻译添加
  buyToEarn: 'Buy to Earn',
  // 直跳页
  最高: 'As max',
  cashback: memberLevelTranslation({
    Cashback: [0, 1],
    Commission: [2, 3]
  }),
  '立即下单升级L1最高获得37%cashback':
    'Placing an order right away to upgrade to L1 can get  37% as maximum commission',
  跳转购买: 'Jump to EARN',
  最高多少佣金: memberLevelTranslation({
    'As max []% Cashback': [0, 1],
    'As max []% Commission': [2, 3]
  }),
  sold: 'Sold',
  last30days: 'last 30 days'
}

export const lazadaTh = {
  // 每日推荐
  每日推荐: 'ดีลเด็ด',
  店铺好价: 'ร้านค้าแนะนำ',
  类目精选: 'หมวดสินค้า',
  下载登录Youpik: 'ลงทะเบียน',
  '最高37%的cashback': 'รับค่าคอมมิชชั่นสูงสุด 37%',
  立即注册: 'ลงทะเบียนเลย',
  'Youpik Terms and Conditions': 'ข้อกำหนดและเงื่อนไข',
  // 高佣合集
  高佣合集: 'ค่าคอมมิชชั่นสูง',
  高佣店铺: 'ร้านค้าแนะนำ',
  高佣精选: 'สินค้าแนะนำ',
  省: memberLevelTranslation({
    เงินคืน: [0, 1],
    คอมมิชชั่น: [2, 3]
  }),
  // dire
  '请阅读以下条款，以保证获得cashback': 'เงื่อนไขสำหรับการรับค่าคอมมิชชั่น',
  跳转: 'คลิก',
  '1次记录1笔佣金': 'กดที่ปุ่มช้อปเลย หรือแบนเนอร์ที่มีสัญลักษณ์ Youpik x Lazada',
  支付: 'สั่งซื้อสินค้า',
  跳转24hr完成: 'ระบบจะพาคุณไปยังแอป Lazada จากนั้นคุณสามารถสั่งซื้อสินค้าจาก Lazada ได้เลย',
  订单: 'รับค่าคอมมิชชั่น',
  支付后48hr内查看: 'คอมมิชชั่น สามารถเช็คค่าคอมมิชชั่นได้ภายใน 48 ชั่วโมง หลังจากชำระเงิน',
  结算: 'ค่าคอมมิชชั่น',
  订单完成后下周五: 'จะได้รับค่าคอมมิชชั่นในวันศุกร์หลังจากคำสั่งซื้อเสร็จสิ้น',
  DOs: 'แนะนำ',
  'You must click through Youpik to Lazada every time you make a new purchase.':
    'ต้องคลิกผ่าน Youpik ไปยัง Lazada ทุกครั้งที่ทำการซื้อใหม่',
  'Ensure that the cart in Lazada is empty before clicking through Youpik.':
    'ทุกครั้งที่ต้องการสั่งซื้อสินค้าใน Lazada ต้องกดผ่านแอป Youpik เท่านั้น',
  'DON’Ts': 'ข้อห้าม',
  'Gift card purchases.': 'ชำระด้วยบัตรกำนัล',
  'Returns, exchanges & cancellations.': 'คืน แลกเปลี่ยน ยกเลิกสินค้า',
  'Fraud orders (for instance, register multiple new Youpik accounts to order from Lazada or order from self-owned Lazada shop) are not accepted.':
    'คำสั่งซื้อที่เป็นการฉ้อโกง (เช่น ลงทะเบียนบัญชี Youpik ใหม่หลายบัญชีเพื่อสั่งซื้อจาก Lazada หรือคำสั่งซื้อจากร้าน Lazada ที่เป็นเจ้าของเอง) จะไม่ได้รับการยืนยัน',
  其他条款和条件: 'ข้อกำหนดและเงื่อนไขเพิ่มเติม',
  'Youpik宣传均为预估佣金，实际订单佣金%如有更改，恕不另行通知。':
    'ค่าคอมมิชชั่นที่แสดงบนหน้าแอป เป็นค่าคอมมิชชั่นโดยประมาณทั้งหมด และค่าคอมมิชชั่นที่ได้รับคำสั่งซื้อจริงอาจเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า',
  更多详情请访问: 'สำหรับรายละอียดเพิ่มเติม สามารถดูเพิ่มเติมได้ที่',
  'Youpik条款与条件。': 'ข้อกำหนดและเงื่อนไข',
  // 商品详情
  商品详情: 'รายละเอียดสินค้า',
  立即下单后升级L1可省: 'สั่งซื้อตอนนี้และอัปเกรดเป็น L1 เพื่อรับส่วนลด',
  预计省: 'ค่าคอมโดยประมาณ',
  点击获取: memberLevelTranslation({
    คลิกเพื่อรับเงินคืน: [0, 1],
    คลิกเพื่อรับค่าคอมมิชชั่น: [2, 3]
  }),
  本单预计节省: 'ค่าคอมโดยประมาณ',
  // 直跳页
  最高: 'สูงสุด',
  cashback: 'คอมมิชชั่น',
  '立即下单升级L1最高获得37%cashback':
    'สั่งซื้อสินค้า เพื่ออัปเกรดเป็น L1 ทันที และรับเงินคืนสูงสุด 37%',
  跳转购买: 'คลิกเพื่อรับค่า',
  最高多少佣金: 'รับเงินคืนสูงสุด [] %',
  sold: 'ขายแล้ว',
  last30days: 'ภายใน 30 วัน',
  buyToEarn: 'ช้อปรับเงินคืน'
}
