export const promptPayEn = {
  header_tip: 'Youpik Payment Protection covers unanthorized transactions made using your Youpik account credentials（T&Cs apply',
  recommendation_method: 'Recommended method(s)',
  prompt_pay: 'PromptPay',
  prompt_pay_tip: 'Please use your mobile banking to scan QR code and complete transaction',
  pay_when_you_receive: 'Pay when you receive',
  prompt_confirm: 'Confirm',
  step_desc_1: '1. Save QR code',
  step_desc_2: '2. Open banking APP',
  step_desc_3: "3. Select 'Scan' and select a photo from album",
  step_desc_4: '4. Select the QR code and complete payment',
  pay_tip_1: "Please complete your payment within ",
  pay_tip_2: " If not completed in time, order will be automatically cancelled. If already completed payment but not showing real time in order status. Please don't do the transaction again.",
}

export const promptPayTh = {
  header_tip: 'การคุ้มครองการชำระเงินของ Youpik จะครอบคลุมไปถึงธุรกรรมที่ไม่ได้รับอนุญาตซึ่งทำโดยใช้ข้อมูลรับรองบัญชี Youpik ของคุณ (เป็นไปตามข้อกำหนดและเงื่อนไข)',
  recommendation_method: 'Recommended method(s)',
  prompt_pay: 'PromptPay',
  prompt_pay_tip: 'กรุณาใช้โทรศัพท์มือถือสแกน QR code และชำระเงิน',
  pay_when_you_receive: 'ชำระเงินเมื่อได้รับ',
  prompt_confirm: 'ตกลง',
  step_desc_1: '1. ดาวน์โหลด QR Code',
  step_desc_2: '2. เปิดแอปพลิเคชั่นธนาคาร',
  step_desc_3: "3. เลือก 'สแกน QR Code' และเลือกรูปภาพจากคลังภาพ",
  step_desc_4: '4. เลือกรูป QR code และชำระเงิน',
  pay_tip_1: "กรุณาชำระเงินให้แล้วเสร็จภายใน ",
  pay_tip_2: " หากไม่ชำระเงินในเวลาที่กำหนด คำสั่งซื้อจะถูกยกเลิกโดยอัติโนมัติ หากชำระเงินเสร็จสิ้นแล้วแต่สถานะสินค้ายังไม่เปลี่ยนแปลง กรุณาอย่าชำระเงินอีกครั้ง",
}