import utils from '@/utils/utils'

const toolsLazadaEnObj = {
  "添加联盟商品": 'Add Affiliate Products',
  "联盟商品添加之前，需要先添加商品到收藏夹": 'Before adding affiliate products to the link, you need to add products to your wishlist first.',
  "目前您的收藏夹里无商品": 'The wishlist is empty now.',
  "去首页添加商品到收藏夹": 'Go to the homepage to add products to wishlist.',
  "即将跳转到首页": 'Jump to the homepage shortly.',
  "返回编辑": 'Back to edit',
  "请挑选您已收藏的商品": 'Please select products that already added to wishlist.',
  "商品列表": 'Product List',
  "已选*件商品": (num) => `Selected&nbsp;<span style="color:#fe2c55;">${num}</span>&nbsp;products`,
  "确认": 'Confirm',
  "多选": 'Select',
  "导入商品": 'Import Products',
  "每一个类目下最多支持导入50件商品": 'Up to 50 products can be imported under each product group.',
  "每一排的产品个数": 'Number of Products per Row',
  "商品类目清单": 'Product Group List',
  "多选": 'Select',
  "products": 'products',
  "是否折叠类目下的商品": 'Hide rest products in every group',
  "折叠": 'Hide',
  "不折叠，展示全部": 'Expand to Show All',
  "重命名": 'Rename',
  "删除": 'Delete',
  "移至底部": 'Move to the bottom',
  "移至顶部": 'Move to the top',
  "类目名称": 'Product group name',
  "模块标题（非必填）": 'Image block title(optional)',
  "模块描述（非必填）": 'Image block description(optional)',
  "目前您的收藏夹全部商品已全部出现在别的类目里": 'All products from your wishlist have been placed in other product groups.',
  "去首页添加新的商品到收藏夹": 'Go to the homepage to add products to wishlist..',
  "类目名称重复": 'Group name duplicated ',
  "添加商品类目": 'Add product group',
  "已选*个类目": (num) => `Selected&nbsp;<span style="color:#fe2c55;">${num}</span>&nbsp;product groups`,
  "确定删除所选*个类目及类目下的所有商品？": (num) => `Confirm to delete the selected &nbsp;<span style="color:#fe2c55;">${num}</span>&nbsp; product groups and all products under them?`,
  "商品编辑页": 'Product Edit Page',
  "选择类目": 'Select product group',
  "商品导入页": 'Product Import Page',
  "每一个类目下最多支持导入50件商品": 'Up to 50 products can be imported under each product group.',
  "您确定删除该商品？": 'Confirm to delete the selected * product groups and all products under them?',
  "更换类目": 'Change product group',
  "移动商品": 'Move Product',
  "移动*件商品至": (num) => `Move ${num} product to`,
  "成功移动商品至": (category, num) => `Move product to ${category} successfully`,
  "您确定删除*件商品？": (num) => `Confirm to delete&nbsp;<span style="color:#fe2c55;">${num}</span>&nbsp;products?`,
  "成功移动*件商品至": (num, category) => `Move ${num} products to ${category} successfully`,
  "移动": 'Move',
  "一个类目下最多支持导入50件商品，该类目已有*件商品，您最多可以移动*件商品": (current, move) => `Each product group supports importing up to &nbsp;<span style="color:#fe2c55;">50</span>&nbsp; products.This product group already has &nbsp;<span style="color:#fe2c55;">${current}</span>&nbsp; products,You can move no more than &nbsp;<span style="color:#fe2c55;">${move}</span>&nbsp; products.`,
  "以上都不想添加": `If you don't want to add any of the above,`,
  "去首页挑选新的商品到收藏夹1": 'go to the',
  "去首页挑选新的商品到收藏夹2": 'homepage',
  "去首页挑选新的商品到收藏夹3": 'to select new products to add to the wishlist.',
  "即将跳转到首页，建议您保存lazada affiliate商品编辑页所有数据": 'Jump to the homepage shortly.We recommend saving all data on the Lazada Affiliate product edit page before jumping.',
  "不保存并跳转": 'Discard and jump',
  "保存并跳转": 'Save and jump',
  上移: 'Move up',
  下移: 'Move down',
  编辑: 'Edit',
  移至顶部1: 'Move to top',
  移至底部1: 'Move to bottom',
  删除1: 'Delete',
}

const toolsLazadaThObj = {
  "添加联盟商品": 'เพิ่มสินค้า Affiliate',
  "联盟商品添加之前，需要先添加商品到收藏夹": 'ก่อนเพิ่มสินค้า Affiliate คุณต้องเพิ่มสินค้าลงรายการโปรดก่อน',
  "目前您的收藏夹里无商品": 'ไม่มีรายการโปรด',
  "去首页添加商品到收藏夹": 'ไปที่หน้าหลักเพื่อเพิ่มสินค้าไปที่รายการโปรด',
  "即将跳转到首页": 'กำลังไปหน้าหลัก',
  "返回编辑": 'กลับไปแก้ไข',
  "请挑选您已收藏的商品": 'โปรดเลือกสินค้าจากในรายการโปรด',
  "商品列表": 'รายการสินค้า',
  "已选*件商品": (num) => `<span style="color:#fe2c55;">${num}</span>สินค้าที่เลือก`,
  "确认": 'ยืนยัน',
  "多选": 'เลือก',
  "导入商品": 'อัปโหลดสินค้า',
  "每一个类目下最多支持导入50件商品": 'แต่ละรายการสามารถอัปโหลดสินค้าได้สูงสุด 50 รายการ',
  "每一排的产品个数": 'จำนวนสินค้าในแต่ละแถว',
  "商品类目清单": 'รายการกลุ่มสินค้า',
  "多选": 'เลือก',
  "products": 'สินค้า',
  "是否折叠类目下的商品": 'ซ่อนสินค้าในทุกกลุ่ม',
  "折叠": 'ซ่อน',
  "不折叠，展示全部": 'ขยายเพื่อแสดงทั้งหมด',
  "重命名": 'เปลี่ยนชื่อ',
  "删除": 'ลบ',
  "移至底部": 'เลื่อนไปด้านล่าง',
  "移至顶部": 'เลื่อนไปด้านบน',
  "类目名称": 'ชื่อกลุ่มสินค้า',
  "模块标题（非必填）": 'ชื่อรูปภาพของบล็อก (ไม่บังคับ)',
  "模块描述（非必填）": 'คำบรรยายรูปภาพของบล็อก (ไม่บังคับ)',
  "目前您的收藏夹全部商品已全部出现在别的类目里": 'สินค้าทั้งหมดจากรายการโปรดได้ย้ายไปยังหน้ากลุ่มสินค้าแล้ว',
  "去首页添加新的商品到收藏夹": 'ไปที่หน้าหลักเพื่อเพิ่มสินค้าไปยังรายการโปรด',
  "类目名称重复": 'ชื่อกลุ่มสินค้าซ้ำกัน',
  "添加商品类目": 'เพิ่มกลุ่มสินค้า',
  "已选*个类目": (num) => `เลือก<span style="color:#fe2c55;">${num}</span>กลุ่มสินค้า`,
  "确定删除所选*个类目及类目下的所有商品？": (num) => 'ยืนยันที่จะลบ',
  "商品编辑页": 'หน้าสำหรับแก้ไขสินค้า',
  "选择类目": 'เลือกกลุ่มสินค้า',
  "商品导入页": 'หน้าสินค้าที่เลือก',
  "每一个类目下最多支持导入50件商品": 'สามารถใส่สินค้าในแต่ละกลุ่มสินค้าสูงสุดได้ 50 ชิ้น',
  "您确定删除该商品？": 'ยืนยันที่จะลบสินค้า',
  "更换类目": 'เปลี่ยนกลุ่มสินค้า',
  "移动商品": 'ย้ายสินค้า',
  "移动*件商品至": (num) => `ย้าย ${num} สินค้าไปยัง`,
  "成功移动商品至": (category, num) => `ย้าย ${num} สินค้าไปยัง ${category} เสร็จสิ้น`,
  "您确定删除*件商品？": (num) => `ยืนยันที่จบลบ<span style="color:#fe2c55;">${num}</span>สินค้า`,
  "成功移动*件商品至": (num, category) => `ย้าย ${num} สินค้าไปยัง ${category} เสร็จสิ้น`,
  "移动": 'ย้ายไปยัง',
  "一个类目下最多支持导入50件商品，该类目已有*件商品，您最多可以移动*件商品": (current, move) => `แต่ละกลุ่มสินค้าสามารถเพิ่มสินค้าได้สูงสุด<span style="color:#fe2c55;">50</span>รายการ สินค้านี้มีอยู่แล้ว<span style="color:#fe2c55;">${current}</span>คุณสามารถเพิ่มสินค้าได้ไม่เกิน<span style="color:#fe2c55;">${move}</span>ชิ้น`,
  "以上都不想添加": `หากไม่ต้องเพิ่มสินค้าแล้ว`,
  "去首页挑选新的商品到收藏夹1": 'หากไม่ต้องเพิ่มสินค้าแล้ว กลับสู่หน้า',
  "去首页挑选新的商品到收藏夹2": 'โฮมเพจ',
  "去首页挑选新的商品到收藏夹3": 'เพื่อเลือกสินค้าเข้าไปในรายการโปรด',
  "即将跳转到首页，建议您保存lazada affiliate商品编辑页所有数据": 'กำลังไปหน้าหลักในไม่ช้านี้ กรุณาบันทึกก่อนที่จะไปยัง Lazada',
  "不保存并跳转": 'ไม่บันทึกและข้าม',
  "保存并跳转": 'บันทึกและข้าม',
  上移: 'เลื่อนขึ้น',
  下移: 'เลื่อนลง',
  编辑: 'แก้ไข',
  移至顶部1: 'เลื่อนไปด้านบน',
  移至底部1: 'เลื่อนไปด้านล่าง',
  删除1: 'ลบ',
}

export const toolsLazadaEn = utils.localeFormat(toolsLazadaEnObj, 'toolsLazada')
export const toolsLazadaTh = utils.localeFormat(toolsLazadaThObj, 'toolsLazada')
