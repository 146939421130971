import utils from '@/utils/utils'

const toolsRevisionEnObj = {
  title: 'Setting',
  domainNameSettings: 'Customize link name',
  globalSettings: 'Block Settings',
  helpContactUs: 'Help & Contact Us',
  usersManual: 'User Manual',
  contcatService: 'Contact Service'
}
const toolsRevisionThObj = {
  title: 'การตั้งค่า',
  domainNameSettings: 'แก้ไขชื่อลิงก์',
  globalSettings: 'ตั้งค่าบล็อก',
  helpContactUs: 'ต้องการความช่วยเหลือหรือติดต่อเรา',
  usersManual: 'คู่มือการใช้',
  contcatService: 'ติดต่อฝ่ายบริการ'
}
export const toolsRevisionEn = utils.localeFormat(toolsRevisionEnObj, 'toolsRevision')
export const toolsRevisionTh = utils.localeFormat(toolsRevisionThObj, 'toolsRevision')
