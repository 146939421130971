import utils from '@/utils/utils'

const toolsCommonEnObj = {
  "保存": 'Save',
  "你的修改将不会被保存。你确定离开吗？": 'Your modifications will not be saved. Are you sure you want to exit?',
  "继续编辑": 'Continue editting',
  "离开": 'Exit',
  "保存": 'Save',
  "复制成功": 'Copy successfully',
  "保存成功": 'Save successfully',
  "应用成功": 'Applied Successfully',
  "应用模板成功": 'Applied Successfully',
  "添加成功": 'Added successfully',
  "预览": 'Preview',
  "确定": 'Confirm',
  "取消": 'Cancel',
  "删除": 'Delete',
  "删除成功": 'Delete successfully',
  "预览模式": 'Preview mode',
  "我知道了": 'Got it',
  "添加": 'Add',
  "全选": 'All',
  "排序": 'Sorting',
  Please_input: "Please input",
  "上传图片": 'Upload Image'
}

const toolsCommonThObj = {
  "保存": 'บันทึก',
  "你的修改将不会被保存。你确定离开吗？": 'การแก้ไขของคุณจะไม่ถูกบันทึก ณแน่ใจหรือไม่ว่าจะออกจากหน้านี้',
  "继续编辑": 'แก้ไขต่อ',
  "离开": 'ออก',
  "保存": 'บันทึก',
  "复制成功": 'คัดลอกสำเร็จ',
  "保存成功": 'บันทึกสำเร็จ',
  "应用成功": 'ใช้งานเทมเพลตสำเร็จ',
  "应用模板成功": 'เลือกเทมเพลตเรียบร้อย',
  "添加成功": 'เพิ่มสำเร็จ',
  "预览": 'ตัวอย่าง',
  "确定": 'ยืนยัน',
  "取消": 'ยกเลิก',
  "删除": 'ลบ',
  "删除成功": 'ลบสำเร็จ',
  "预览模式": 'โหมดแสดงตัวอย่าง',
  "我知道了": 'เข้าใจแล้ว',
  "添加": 'เพิ่ม',
  "全选": 'เลือกทั้งหมด',
  "排序": 'ลำดับ',
  Please_input: "กรุณาใส่ข้อมูล",
  "上传图片": 'อัปโหลดรูปภาพ'
}

export const toolsCommonEn = utils.localeFormat(toolsCommonEnObj, 'toolsCommon')
export const toolsCommonTh = utils.localeFormat(toolsCommonThObj, 'toolsCommon')
