export const activityEn = {
  activity_rules: 'Terms & Conditions',
  end_in: 'End in',
  activity_rules_1:
    'The number of rewards displayed on the scoreboard may be different from the final reward amount received.',
  activity_rules_2: 'Only the direct order value is calculated.',
  activity_rules_3: 'Prizes are subject to 5% VAT.',
  activity_rules_4: 'The award is expected to be paid on February 5, 2022.',
  activity_rules_lo_4: 'It is expected that YOUPIKKER will receive the bonus on February 25, 2022.',
  activity_rules_n_4: 'It is expected that YOUPIKKER will receive the bonus on March 11, 2022.',
  activity_rules_lx_4: 'It is expected that YOUPIKKER will receive the bonus on April 1, 2022.',
  activity_rules_ycsr_4: 'It is expected that YOUPIKKER will receive the bonus on April 29, 2022.',

  activity_rules_5:
    'We reserve the right to change the terms and conditions. If any fraud is found, the increased commission will be invalid.',
  activity_rules_6: 'Other company regulations',

  activity_rules_7: 'The list data will be updated until January 26, 2022',
  activity_rules_lo_7: 'The list data will be updated until February 20, 2022',
  activity_rules_n_7: 'The list data will be updated until March 2, 2022.',
  activity_rules_lx_7: 'The list data will be updated until March 21, 2022',
  activity_rules_ycsr_7: 'The list data will be updated until April 19, 2022',

  estimated: 'Estimated',
  confirmed: 'Confirmed',
  estimated_ranking: 'Estimated ranking',

  actual_ranking: 'Actual ranking',
  data_update: 'Data Update',
  no_one_is_on_the_list: 'No Data',
  activity_has_ended: 'Promotion has ended',
  view_details: 'View Details',
  don_t_remind_again: 'Never Remind',
  begin_in: 'Start in',
  join_now: 'Join Now',
  Valid_for: 'expire in ',
  from_collection: ' days',
  //下单奖励
  place_rewards: 'Go to Lazada',
  gotoYoupik: 'Go to Youpik',
  participated: '已参加',
  participatedTips:
    'Users who meet the above conditions will receive 20 baht after the order is completed, if they do not meet the above conditions, there will be no 20 baht.',
  openApp: '此网站请求打开app',
  openAppWords: '打开',
  needaccessAppTip: 'Participating in this campaign requires access to Youpik APP',
  'Invite you to download the uChoice Pro': `Invite you to download the uChoice Pro and join the community for TikTok creators to make the sales of videos and livestream. Let's earn higher commissions.`,
  Cancel: 'Cancel',
  'Get the APP': 'Get the APP',
  nowOpen: '正在为您打开........',
  continueShopping: 'Stay',
  downloadApp: 'Download APP',
  thisEventHasEnded: 'The campaign has ended.',
  registered: 'Participated',
  theEventIsFull: 'The campaign is full.',

  Share450_Detail_Title: 'Invite Reward',
  Share450_Time_Rules:
    'Campaign start from 16 - 29 Jan 2023 Announcement of results and receiving rewards on 17 Feb 2023',
  Share450_Completed: 'Completed!',
  Share450_NewYoupikker: 'New Youpikker',
  Share450_Total_Price: 'Order Amount',
  Share450_Total_Price2: 'Amounts of completed orders',
  Share450_Total_Congratulation: 'Congratulation!'
}

export const activityTh = {
  activity_rules: 'เงื่อนไขและข้อกำหนด',
  end_in: 'สิ้นสุดใน',
  activity_rules_1: 'จำนวนรางวัลที่แสดงบนกระดานคะแนนอาจแตกต่างไปจากจำนวนรางวัลสุดท้ายที่ได้รับ',
  activity_rules_2: 'คำนวณเฉพาะมูลค่าการสั่งซื้อโดยตรง',
  activity_rules_3: 'ของรางวัลไม่รวมภาษีมูลค่าเพิ่ม 5%',

  activity_rules_4: ' รางวัลกิจกรรมคาดว่าจะโอนได้ในวันที่ 5 กุมภาพันธ์ 2022',
  activity_rules_lo_4: ' YOUPIKKER จะได้รับโบนัสประมาณวันที่ 25 กุมภาพันธ์ พ.ศ. 2565',
  activity_rules_n_4: 'รางวัลกิจกรรมคาดว่าจะโอนได้ในวันที่ 11 มีนาคม 2022',
  activity_rules_lx_4: ' คาดว่า YOUPIKKER จะได้รับโบนัสในวันที่ 1 เมษายน 2565',
  activity_rules_ycsr_4: ' คาดว่า YOUPIKKER จะได้รับโบนัสในวันที่ 29 เมษายน 2565',

  activity_rules_5:
    'ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไข หากพบว่ามีการฉ้อโกง ค่าคอมมิชชั่นที่เพิ่มขึ้นจะถือเป็นโมฆะ',
  activity_rules_6: 'ข้อบังคับของบริษัทอื่นๆ',

  activity_rules_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 26 มกราคม 2022',
  activity_rules_lo_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 20 กุมภาพันธ์ 2022',
  activity_rules_n_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 2 มีนาคม 2022',
  activity_rules_lx_7: 'ข้อมูลลีดเดอร์บอร์ดจะอัปเดตจนถึงวันที่ 21 มีนาคม 2565',
  activity_rules_ycsr_7: 'ข้อมูลลีดเดอร์บอร์ดจะอัปเดตจนถึงวันที่ 19 เมษายน 2565',

  estimated: 'โดยประมาณ',
  confirmed: 'ยอดขายที่ยืนยัน',
  estimated_ranking: 'อันดับโดยประมาณ',
  actual_ranking: 'อันดับตามจริง',
  data_update: 'อัพเดทข้อมูล',
  no_one_is_on_the_list: 'ไม่มีข้อมูล',
  activity_has_ended: 'หมดเวลาโปรโมชั่นแล้ว',
  view_details: 'รายละเอียด',
  don_t_remind_again: 'อย่าเตือนอีก',
  begin_in: 'เริ่มใน',
  join_now: 'เข้าร่วมเดี๋ยวนี้',
  Valid_for: 'หมดอายุภายใน ',
  from_collection: ' วัน',
  //下单奖励
  place_rewards: 'Go to Lazada',
  gotoYoupik: 'Go to Youpik',
  participated: '已参加',
  participatedTips:
    'ผู้ใช้ที่ปฎิบัติตามเงื่อนไขข้างต้นจะได้รับเงินคืน 20 บาทหลังจากคำสั่งซื้อเสร็จสมบูรณ์ หากไม่ปฎิบัติตามเงื่อนไขดังกล่าวจะไม่ได้เงินคืน 20 บาท',
  openApp: '此网站请求打开app',
  openAppWords: '打开',
  needaccessAppTip: 'ดาวน์โหลด Youpik เพื่อรับสิทธิพิเศษเลย!',
  'Invite you to download the uChoice Pro': `ถ้าอยากเพิ่มยอดขายบน TikTok และรับค่าคอมที่มากกว่า ดาวน์โหลด uChoice Pro เลย!`,
  Cancel: 'ยกเลิก',
  'Get the APP': 'ดาวน์โหลด',
  nowOpen: '正在为您打开........',
  continueShopping: 'ไม่ดีกว่า',
  downloadApp: 'ดาวน์โหลด Youpik',
  thisEventHasEnded: 'กิจกรรมสิ้นสุดแล้ว',
  registered: 'ใช้สิทธิแล้ว',
  theEventIsFull: 'สิทธิเต็มจำนวนแล้ว',

  Share450_Detail_Title: 'ส่งคำเชิญเพื่อรับโบนัส',
  Share450_Time_Rules:
    'เริ่มร่วมกิจกรรมตั้งแต่วันที่ 16 - 29 ม.ค. 66 ประกาศผลและได้รับเงินโบนัส วันที่ 17 ก.พ. 66',
  Share450_Completed: 'ชวนเพื่อนสำเร็จ!',
  Share450_NewYoupikker: 'ผู้ใช้ใหม่',
  Share450_Total_Price: 'จำนวนคำสั่งซื้อ',
  Share450_Total_Price2: 'คำสั่งซื้อสำเร็จทั้งหมด',
  Share450_Total_Congratulation: 'ยินดีด้วย คุณทำภารกิจสำเร็จ!'
}
